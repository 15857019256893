import styles from './Tournament.module.css';
import BasePageWrapper from "@/components/BasePageWrapper/BasePageWrapper";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { acceptInviteForTournament, cancelTournament, coachLoadList, createRequestChangeTournamentOwner, entryTournament, loadTournament, loadTournamentRoundsPreview, loadTournamentStatistics, ownerKickPlayerTournament, rejectInviteForTournament, startTournament } from "./service";
import { Gender, InvitationStatus, PlayerProfileInfo, TicketType, TournamentKind, TournamentStatus } from "@/integration-api/server-rest-lundapadelApi";
import TournamentCard from "@/components/TournamentCard/TournamentCard";
import PageBackground from "@/components/PageBackground/PageBackground";
import bgImage from '@/static/images/tournament.png';
import Header from '@/components/Header/Header';
import { TournamentButtonAction, TournamentButtonParams, TournamentPageModalState, TournamentPageState, TournamentResultsTabsValues } from './TournamentPage.interfaces';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import { useAppSelector } from '@/hooks/hooks';
import Button, { ButtonVariants } from '@/components/Button/Button';
import joinIcon from '@/static/images/icons/join-tournament-icon.svg';
import TournamentParticipants from './components/TournamentParticipants/TournamentParticipants';
import { PuffLoader } from 'react-spinners';
import pencilIcon from '@/static/images/icons/pencil-icon.svg';
import { shareTournament } from './components/TournamentTableItem/utils';
import TournamentTableItem from './components/TournamentTableItem/TournamentTableItem';
import TournamentResultsTabs from './components/TournamentResultsTabs/TournamentResultsTabs';
import TournamentStatistics from './components/TournamentStatistics/TournamentStatistics';
import TournamentRoundsPreview from './components/TournamentRoundsPreview/TournamentRoundsPreview';
import { useImmer } from 'use-immer';
import roundStyles from './components/TournamentRoundsPreview/TournamentRoundsPreview.module.css';
import EditGameIcon from '@/static/images/icons/EditGameIcon';
import ShareIcon from '@/static/images/icons/ShareIcon';
import EntityCalendarIcon from '@/static/images/icons/EntityCalendarIcon';
import { createImgSrc, ErrorUtils } from '@/utils/utils';
import DBManager from '@/utils/DBManager/DBManager';
import TournamentActionModal from './components/TournamentActionModal/TournamentActionModal';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import OwnerButton from './components/OwnerButton/OwnerButton';
import { useWebsocketTicket } from '../game/GameStatusContent/utils';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import EntityWaitingList from '@/components/EntityWaitingList/EntityWaitingList';
import EntityWaitingListModal from '@/components/EntityWaitingListModal/EntityWaitingListModal';
import DetailedPageOptionExpantion from '@/components/DetailedPageOptionExpantion/DetailedPageOptionExpantion';
import { DetailedPageOptionExpantionOption } from '@/components/DetailedPageOptionExpantion/DetailedPageOptionExpantion.interfaces';
import CancelIcon from '@/static/images/icons/CancelIcon';
import CrossIcon from '@/static/images/icons/CrossIcon';
import SwapOwner from '@/components/Icon/components/SwapOwner';
import ChangeOwnershipModal from '@/components/ChangeOwnershipModal/ChangeOwnershipModal';
import { acceptRequestChangeTournamentOwner } from '../home/components/OwnershipChangeRequestsSection/service';

const statusPlannedStarted = [TournamentStatus.PLANNED, TournamentStatus.START];
const statusEditAfterFinished = [TournamentStatus.EDITABLE, TournamentStatus.AFTER_TOURNAMENT, TournamentStatus.FINISH];
const statusPlannedStartedCanceled = [TournamentStatus.PLANNED, TournamentStatus.START, TournamentStatus.CANCEL];
const statusEditAfter = [TournamentStatus.EDITABLE, TournamentStatus.AFTER_TOURNAMENT];
const statusAfterFinishedCanceled = [TournamentStatus.AFTER_TOURNAMENT, TournamentStatus.CANCEL, TournamentStatus.FINISH];

const TournamentPage = () => {
  const current = useAppSelector(state => state.auth.currentProfile);
  const { id } = useParams();
  const navigate = useNavigate();

  const [tournamentState, setTournamentState] = useImmer<TournamentPageState>({
    tournament: {},
    coaches: [],
    rounds: [],
    activeTab: 'results',
    editMode: false,
  });

  const [tournamentModalsState, setTournamentModalsState] = useImmer<TournamentPageModalState>({
    cancelTournamentVisible: false,
    joinQueueVisible: false,
    kickPlayerVisible: false,
    leaveQueueVisible: false,
    leaveTournamentVisible: false,
    removeInviteVisible: false,
    delegateOwnershipVisible: false
  });

  const webSocketURL = useWebsocketTicket(id, TicketType.TOURNAMENT);

  const [waitingListVisible, setWaitingListVisible] = useState(false);
  const [changeOwnersipVisible, setChangeOwnersipVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);

  const buttonsRef = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const isOwner = tournamentState.tournament?.owner?.uid === current?.identity?.uid;

  const handleButtonAction = useCallback(async (action: TournamentButtonAction, params?: TournamentButtonParams) => {
    try {      
      setLoading(true);
      switch(action) {
        case 'start': {
          if(tournamentState.tournament?.status === TournamentStatus.PLANNED) {
            await startTournament({ tournamentUid: id });
          }
          navigate(`/tournament/${id}/results`);
          break;
        }
        case 'cancel': {
          await cancelTournament({
            tournamentUid: id,
          });
          setTournamentModalsState(state => { state.cancelTournamentVisible = false });
          if(id && !webSocketURL) {
            const { result } = await loadTournament(id);
            if(result) {
              setTournamentState(state => { state.tournament = result });
            }
          }
          break;
        }
        case 'join': {
          await entryTournament({
            tournamentUid: id,
            participation: true
          })
          setTournamentModalsState(state => { state.joinQueueVisible = false });
          if(id && !webSocketURL) {
            const { result } = await loadTournament(id);
            if(result) {
              setTournamentState(state => { state.tournament = result });
            }
          }
          break;
        }
        case 'leave': {
          await entryTournament({
            tournamentUid: id,
            participation: false
          })
          setTournamentModalsState(state => {
            state.leaveQueueVisible = false;
            state.leaveTournamentVisible = false;
          })
          if(id && !webSocketURL) {
            const { result } = await loadTournament(id);
            if(result) {
              setTournamentState(state => { state.tournament = result });
            }
          }
          break;
        }
        case 'kick': {
          const { result } = await ownerKickPlayerTournament({
            playerUid: params?.uid,
            tournamentUid: id
          })
          setTournamentModalsState(state => {
            state.kickPlayerVisible = false;
            state.playerData = undefined;
          });
          if(result && !webSocketURL) {
            setTournamentState(state => { state.tournament = result });
          }
          break;
        }
        case 'removeInvite': {
          await rejectInviteForTournament({
            playerUid: params?.uid,
            tournamentUid: id
          });
          setTournamentModalsState(state => {
            state.removeInviteVisible = false;
            state.playerData = undefined;
          });
          if(id && !webSocketURL) {
            const { result } = await loadTournament(id);
            if(result) {
              setTournamentState(state => { state.tournament = result });
            }
          }
          break;
        }
        case 'handleInvitation': {
          await acceptInviteForTournament({
            tournamentUid: id,
            accepted: !!params?.accept
          });
          if(id && !webSocketURL) {
            const { result } = await loadTournament(id);
            if(result) {
              setTournamentState(state => { state.tournament = result });
            }
          }
          break;
        }
        case 'delegateOwnership': {
          await createRequestChangeTournamentOwner({
            player: { uid: params?.uid },
            relatedEntityUid: id
          });
          setTournamentModalsState(state => {
            state.delegateOwnershipVisible = false;
            state.playerData = undefined;
          });
          setChangeOwnersipVisible(false);
          if(id && !webSocketURL) {
            const { result } = await loadTournament(id);
            if(result) {
              setTournamentState(state => { state.tournament = result });
            }
          }
          break;
        }
        case 'handleOwnershipChange': {
          await acceptRequestChangeTournamentOwner({ accepted: params?.accept, relatedEntityUid: id });
          if(id && !webSocketURL) {
            const { result } = await loadTournament(id);
            if(result) {
              setTournamentState(state => { state.tournament = result });
            }
          }
          break;
        }
        default: 
          const _: never = action;
          throw new Error('not all actions are handled');
      }
    } catch(err) {
      ErrorUtils.handleErrorMessage(err);
    } finally {
      setLoading(false);
    }
  }, [id, tournamentState.tournament, tournamentModalsState, tournamentState.coaches]);

  const handleGoBack = useMemo(() => {
    if (
      window.location.search.includes('afterCreate') ||
      window.location.search.includes('redirect') ||
      window.history.length === 1
    ) {
      return () => navigate('/play');
    }
  }, [navigate]);

  const selectPlayerForKick = useCallback((player?: PlayerProfileInfo) => {
    setTournamentModalsState(state => {
      state.playerData = {
        displayName: player?.displayName,
        uid: player?.uid
      };
      state.kickPlayerVisible = true;
    })
  }, []);

  const isParticipatating = useMemo(() => {
    return !!tournamentState.tournament?.members?.find(member => member.player?.uid === current?.identity?.uid)
  }, [tournamentState.tournament?.members, current?.identity?.uid]);

  const isQueued = useMemo(() => {
    return !!tournamentState.tournament?.queue?.find(member => member.player?.uid === current?.identity?.uid);
  }, [tournamentState.tournament?.queue, current?.identity?.uid]);

  const isTournamentFull = useMemo(() => {
    return tournamentState.tournament?.members?.length === tournamentState.tournament?.playersLimit;
  }, [tournamentState.tournament?.members?.length, tournamentState.tournament?.playersLimit]);

  const hasBeenInvited = useMemo(() => {
    return !!tournamentState.tournament.invitations?.find(inv => inv.player?.uid === current.identity?.uid && inv.invitationStatus === InvitationStatus.SENT);
  }, [tournamentState.tournament?.invitations, current.identity?.uid]);

  const renderTabContent = () => {
    if(tournamentState.tournament?.kind !== TournamentKind.CLASSIC) {
      switch(tournamentState.activeTab) {
        case 'results': {
          return (
            <div className={styles['table']}>
              {tournamentState.tournament?.members?.map?.((participant, index) => (
                <TournamentTableItem
                  key={participant.player?.uid}
                  participant={participant}
                  placement={index + 1}
                />
              ))}
            </div>
          )
        }
        case 'score': {
          return (
            <div className={roundStyles['wrapper']}>
              {tournamentState.rounds?.map((round, index) => (
                <TournamentRoundsPreview
                  key={index}
                  round={round}
                  index={index}
                  roundStickyTb={58}
                  courtStickyTb={94}
                />
              ))}
            </div>
          )
        }
        case 'statistic': {
          return (
            <TournamentStatistics
              tournamentStatistic={tournamentState.tournamentStatistic}
            />
          )
        }
        default: {
          const _: never = tournamentState.activeTab;
          throw new Error('failed');
        }
      }
    } else {
      return (
        <div className={styles['table']}>
          {tournamentState.tournament?.members?.map((participant, index) => (
            <TournamentTableItem
              key={participant.player?.uid}
              participant={participant}
              placement={index + 1}
            />
          ))}
        </div>
      )
    }
  }

  const renderCalendarButton = useMemo(() => {
    if(Capacitor.isNativePlatform()){
      return (
        <div className={styles['tournament-option']} onClick={async () => {
          try {
            if(Capacitor.getPlatform() === 'ios') {
              await Browser.open({
                url: `${createImgSrc.origin}/api/event/export?entityUid=${id}&entityType=TOURNAMENT&type=&inline=true`,
                presentationStyle: 'popover'
              });
            } else {
              const { path } = await Filesystem.downloadFile({
                path: 'calendar.ics',
                directory: Directory.Data,
                url: `${createImgSrc.origin}/api/event/export?entityUid=${id}&entityType=TOURNAMENT&type=&inline=true`,
              });
              if(path) {
                await FileOpener.open({
                  filePath: path
                });
              }
            }
          } catch(err) {
            PopupMessage.open('Произошла ошибка')
          }
        }}>
          Добавить в календарь
          <EntityCalendarIcon fill='black'/>
        </div>
      )
    } else {
      return (
        <a className={styles['tournament-option']} href={`${createImgSrc.origin}/api/event/export?entityUid=${id}&entityType=TOURNAMENT&type=&inline=true`} download>
          Добавить в календарь
          <EntityCalendarIcon fill='black'/>
        </a> 
      )
    }
  }, [id]);

  const detailedPageOptions = useMemo(() => {
    const options: Array<DetailedPageOptionExpantionOption> = [];
    options.push({
      value: 'calendar',
      label: renderCalendarButton
    });
    options.push({
      value: 'share',
      label: (              
        <div
          className={styles['tournament-option']} 
          onClick={() => shareTournament(tournamentState.tournament)}
        >
          Поделиться
          <ShareIcon fill='black'/>
        </div>
      )
    });
    if(isOwner && tournamentState.tournament?.status === TournamentStatus.PLANNED) {
      options.push({
        value: 'delegate',
        label: (
          <div
            className={styles['tournament-option']}
            onClick={() => setChangeOwnersipVisible(true)}
          >
            Передать права
            <SwapOwner iconSize='middle' />
          </div>
        )
      })
    }
    if(isOwner && !statusAfterFinishedCanceled.includes(tournamentState.tournament?.status ?? TournamentStatus.START)) {
      options.push({
        value: 'cancel',
        label: (
          <div
            className={styles['tournament-option']}
            style={{ color: '#FF3B30' }}
            onClick={() => setTournamentModalsState(state => { state.cancelTournamentVisible = true })}
          >
            Отменить турнир
            <CancelIcon/>
          </div>
        )
      })
    }
    return options;
  }, [renderCalendarButton, tournamentState.tournament, isOwner, tournamentModalsState.cancelTournamentVisible]);

  const renderButtons = useMemo(() => {
    if(statusPlannedStarted.includes(tournamentState.tournament?.status!)) {
      if(tournamentState.editMode) {
        if(isOwner) {
          return (
            <Button
              variant={ButtonVariants.PRIMARY}
              onClick={() => setTournamentState(state => { state.editMode = false })}
              disabled={!navigator.onLine}
            >
              Сохранить состав игроков
            </Button>
          )
        } else {
          return null;
        }
      } else {
        return (
          <>
            {isOwner ?
              <>
                {isTournamentFull ?
                  <Button
                    variant={ButtonVariants.PRIMARY}
                    onClick={() => handleButtonAction('start')}
                    disabled={!navigator.onLine}
                  >
                    Начать турнир
                  </Button> : null
                }
                {tournamentState.tournament?.status === TournamentStatus.PLANNED && tournamentState.tournament.privateTournament && !isTournamentFull ?
                  <Button
                    variant={ButtonVariants.PRIMARY_OUTLINED}
                    onClick={() => navigate(`/tournament/${id}/invitation`)}
                  >
                    <div className={styles['button-with-icon']}>
                      <span>
                        <CrossIcon fill='var(--green-lunda)'/>
                      </span>
                      <span>Пригласить игроков</span>
                    </div>
                  </Button> : null
                }
              </> : 
              <>
                {tournamentState.tournament?.hasReceiveOwnershipRequest ?
                    <>
                    <span className={styles['invite-received']}>Вас просят стать организатором турнира</span>
                      <Button
                        variant={ButtonVariants.PRIMARY}
                        onClick={() => handleButtonAction('handleOwnershipChange', { accept: true })}
                        disabled={!navigator.onLine}
                      >
                        Принять
                      </Button>
                      <Button
                        variant={ButtonVariants.GRAYBLUE_OUTLINED}
                        onClick={() => handleButtonAction('handleOwnershipChange', { accept: false })}
                        disabled={!navigator.onLine}
                      >
                        Отклонить
                      </Button>
                  </> : hasBeenInvited ?
                  <>
                    <span className={styles['invite-received']}>Вы приглашены в турнир!</span>
                      <Button
                        variant={ButtonVariants.PRIMARY}
                        onClick={() => handleButtonAction('handleInvitation', { accept: true })}
                        disabled={!navigator.onLine}
                      >
                        Принять
                      </Button>
                      <Button
                        variant={ButtonVariants.GRAYBLUE_OUTLINED}
                        onClick={() => handleButtonAction('handleInvitation', { accept: false })}
                        disabled={!navigator.onLine}
                      >
                        Отклонить
                      </Button>
                  </> :
                  <>
                    {isParticipatating && !isQueued && tournamentState.tournament?.status === TournamentStatus.PLANNED ?
                      <Button
                        variant={ButtonVariants.CANCEL}
                        bordered
                        onClick={() => setTournamentModalsState(state => { state.leaveTournamentVisible = true })}
                        disabled={!navigator.onLine}
                      >
                        Отменить участие
                      </Button> : null
                    }
                    {!isParticipatating && isQueued && tournamentState.tournament?.status === TournamentStatus.PLANNED ?
                      <Button
                        variant={ButtonVariants.CANCEL}
                        bordered
                        onClick={() => setTournamentModalsState(state => { state.leaveQueueVisible = true })}
                        disabled={!navigator.onLine}
                      >
                        Покинуть лист ожидания
                      </Button> : null
                    }
                  </>
                }
              </>
            }
            {tournamentState.tournament?.available && !tournamentState.tournament?.hasReceiveOwnershipRequest && !hasBeenInvited && tournamentState.tournament?.genders?.includes(current?.gender!) ?
              <>
                {(!isParticipatating && !isQueued) ? tournamentState.tournament?.members?.length !== tournamentState.tournament?.playersLimit ?
                  <Button
                    variant={ButtonVariants.PRIMARY}
                    className={styles['button']}
                    onClick={() => handleButtonAction('join')}
                    disabled={!navigator.onLine}
                  >
                    <img src={joinIcon} alt='иконка вступления'/>
                    <span>Участвовать в турнире</span>
                  </Button> :
                  <Button
                    variant={ButtonVariants.BLUE}
                    onClick={() => setTournamentModalsState(state => { state.joinQueueVisible = true })}
                    disabled={!navigator.onLine}
                  >
                    Занять очередь в листе ожидания
                  </Button> : null
                }
              </> : null
            }
          </>
        );
      }
    }
    if(statusEditAfter.includes(tournamentState.tournament?.status!)) {
      if(isTournamentFull && !tournamentState.editMode && isOwner) {
        return (
          <Button
            variant={ ButtonVariants.BLUE}
            onClick={() => navigate(`/tournament/${id}/results`)}
            disabled={!navigator.onLine}
          >
            <span className={styles['button-with-icon']}>
              <img src={pencilIcon} alt="иконка счета" />
              <span>Редактировать результат турнира</span>
            </span>
          </Button>
        );
      }
    }
    return null;
  }, [handleButtonAction, isTournamentFull, isQueued, isParticipatating, hasBeenInvited, isOwner, isTournamentFull, tournamentModalsState, tournamentState.editMode, tournamentState.tournament?.available, tournamentState.tournament?.genders, tournamentState.tournament?.status, tournamentState.tournament?.members?.length, tournamentState.tournament?.playersLimit, tournamentState.tournament.privateTournament, tournamentState.tournament?.hasReceiveOwnershipRequest]);

  const fetchTournament = () => {
    if(id) {
      Promise.all([
        loadTournament(id),
        loadTournamentStatistics(id),
        loadTournamentRoundsPreview(id)
      ])
        .then(([{ result }, { result: resultStats }, { result: resultRounds }]) => {
          setTournamentState(state => {
            state.tournament = result ?? {};
            state.tournamentStatistic = resultStats?.playersStatistics;
            state.rounds = resultRounds?.rounds ?? []
          });
          DBManager.create('tournaments', {
            tournament: result,
            rounds: resultRounds?.rounds,
            tournamentStatistic: resultStats?.playersStatistics
          }, id);
        })
        .catch(err => ErrorUtils.handleErrorMessage(err))
        .finally(() => setLoading(false));
    }
  }

  useEffect(() => {
    if(id) {
      if(navigator.onLine) {
        setLoading(true);
        fetchTournament();
        coachLoadList().
          then(({ result }) => {
            setTournamentState(state => { state.coaches = result?.infos ?? [] });
          })
          .catch(err => ErrorUtils.handleErrorMessage(err))
      } else {
        DBManager.read('tournaments', id)
          .then(tournamentInfo => {
            setTournamentState(state => {
              state.tournament = tournamentInfo?.tournament;
              state.rounds = tournamentInfo?.rounds;
              state.tournamentStatistic = tournamentInfo?.tournamentStatistic;
            });
          })
          .catch((err) => console.log(err));
      }
    }
  }, [id]);

  useEffect(() => {
    let ignoreWSConnection = false;
    const socket = webSocketURL ? new WebSocket(webSocketURL) : null;

    if (socket) {
      socket.onopen = (e) => {
        if (ignoreWSConnection) {
          socket?.close();
        } else {
          console.log('Websocket is open!');
          if(intervalRef.current) {
            clearInterval(intervalRef.current);
          }
          intervalRef.current = setInterval(() => socket.send('ping'), 10000);
        }
      };
    
      socket.onerror = (error) => {
        console.log(error);
      };

      socket.onmessage = ({ data }) => {
        const { eventType } = JSON.parse(data);
        if (eventType === 'UPDATED') {
          fetchTournament();
        }
      };
    }
  
    return () => {
      if(intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (socket?.readyState === 1) {
        socket?.close();
      } else {
        ignoreWSConnection = true;
      }
    }
  }, [webSocketURL]);

  useEffect(() => {
    //для фейковых паддингов, чтобы кнопки не перекрывали контент
    setOffset((buttonsRef.current?.clientHeight ?? 0) + 28);
  }, [buttonsRef.current?.clientHeight, offset, tournamentState, waitingListVisible]);

  return ( 
    <>
      <TournamentActionModal
        type='cancelTournamentVisible'
        visible={tournamentModalsState.cancelTournamentVisible}
        onClose={() => setTournamentModalsState(state => { state.cancelTournamentVisible = false })}
        handler={() => handleButtonAction('cancel')}
      />
      <TournamentActionModal
        type='leaveTournamentVisible'
        visible={tournamentModalsState.leaveTournamentVisible}
        onClose={() => setTournamentModalsState(state => { state.leaveTournamentVisible = false })}
        handler={() => handleButtonAction('leave')}
      />
      <TournamentActionModal
        type='leaveQueueVisible'
        visible={tournamentModalsState.leaveQueueVisible}
        onClose={() => setTournamentModalsState(state => { state.leaveQueueVisible = false })}
        handler={() => handleButtonAction('leave')}
      />
      <TournamentActionModal
        type='joinQueueVisible'
        visible={tournamentModalsState.joinQueueVisible}
        onClose={() => setTournamentModalsState(state => { state.joinQueueVisible = false })}
        handler={() => handleButtonAction('join')}
      />
      <TournamentActionModal
        type='kickPlayerVisible'
        visible={tournamentModalsState.kickPlayerVisible}
        onClose={() => setTournamentModalsState(state => {
          state.playerData = undefined;
          state.kickPlayerVisible = false;
        })}
        handler={uid => handleButtonAction('kick', { uid })}
        playerData={tournamentModalsState.playerData}
      />
      <TournamentActionModal
        type='removeInviteVisible'
        visible={tournamentModalsState.removeInviteVisible}
        onClose={() => setTournamentModalsState(state => {
          state.playerData = undefined;
          state.removeInviteVisible = false;
        })}
        handler={uid => handleButtonAction('removeInvite', { uid })}
        playerData={tournamentModalsState.playerData}
      />
      <TournamentActionModal
        type='delegateOwnershipVisible'
        visible={tournamentModalsState.delegateOwnershipVisible}
        onClose={() => setTournamentModalsState(state => {
          state.playerData = undefined;
          state.delegateOwnershipVisible = false;
        })}
        handler={uid => handleButtonAction('delegateOwnership', { uid })}
        playerData={tournamentModalsState.playerData}
      />
      <ChangeOwnershipModal
        visible={changeOwnersipVisible}
        onClose={() => setChangeOwnersipVisible(false)}
        options={tournamentState.coaches}
        delegateOnwership={playerData => setTournamentModalsState(state => { 
          state.delegateOwnershipVisible = true;
          state.playerData = playerData;
        })}
      />
      <EntityWaitingListModal
        entity='tournament'
        visible={waitingListVisible}
        onClose={() => setWaitingListVisible(false)}
        invitations={tournamentState.tournament?.invitations ?? []}
        owner={isOwner}
        queue={tournamentState.tournament?.queue ?? []}
        loading={loading}
        deleteFromWaitingList={playerData => setTournamentModalsState(state => { 
          state.kickPlayerVisible = true;
          state.playerData = playerData;
        })}
        clearInviteFromTournament={playerData => setTournamentModalsState(state => {
          state.removeInviteVisible = true;
          state.playerData = playerData;
        })}
      /> 
      {!(waitingListVisible || changeOwnersipVisible) ? (
          <BasePageWrapper>
            <PageBackground imgSrc={bgImage} />
            <Header
              handleClick={handleGoBack}
              className={styles['header']}
              additional={
                <div className={styles['header-buttons']}>
                  {isOwner && statusPlannedStarted.includes(tournamentState.tournament?.status!) ?
                    <div className={styles['share-button']} onClick={() => navigate(`/tournament/${id}/settings`)}>
                      <EditGameIcon/>
                    </div> : null
                  }
                  <DetailedPageOptionExpantion
                    options={detailedPageOptions}
                  />
                </div>
              }>
              Турнир
            </Header>
            <div className={styles['wrapper']}>
              {loading ?
                <PuffLoader color='#4EB857' cssOverride={{ margin: 'auto' }}/> :
                <>
                  <div className={styles['padding-20']}>
                    <TournamentCard tournament={tournamentState.tournament} />
                  </div>
                  {!isOwner && statusPlannedStarted.includes(tournamentState.tournament?.status!) ?
                    <div className={styles['padding-20']} style={{ marginTop: '8px' }}>
                      <OwnerButton
                        owner={tournamentState.tournament?.owner}
                        onClick={() => navigate(`/profile/${tournamentState.tournament?.owner?.uid}`)}
                      />
                    </div> : null
                  }
                  {tournamentState.tournament?.kind !== TournamentKind.CLASSIC && !statusPlannedStartedCanceled.includes(tournamentState.tournament?.status!) ?
                    <TournamentResultsTabs
                      name='tournament-tabs'
                      activeTab={tournamentState.activeTab}
                      selectTab={e => setTournamentState(state => { state.activeTab = (e.target as HTMLInputElement).value as TournamentResultsTabsValues })}
                      style={{ paddingBottom: tournamentState.activeTab === 'score' ? '28px' : '0' }}
                    /> 
                    : null
                  }
                  {statusEditAfterFinished.includes(tournamentState.tournament?.status!) ?
                    <>
                      {renderTabContent()}
                    </> :
                    <div>
                      {tournamentState.tournament?.members?.length ?
                        <TournamentParticipants
                          owner={tournamentState.tournament?.owner?.uid === current?.identity?.uid}
                          isOver={![TournamentStatus.PLANNED, TournamentStatus.START].includes(tournamentState.tournament?.status!)}
                          editMode={navigator.onLine && tournamentState.editMode}
                          setEditMode={(value: boolean) => setTournamentState(state => { state.editMode = value })}
                          participants={tournamentState?.tournament?.members ?? []}
                          kickPlayer={(player?: PlayerProfileInfo) => selectPlayerForKick(player)}
                        /> : null
                      }
                      {tournamentState.tournament?.queue?.length || tournamentState.tournament?.invitations?.length ? 
                        <div className={styles['padding-20']}>
                          <EntityWaitingList
                            entity='tournament'
                            invitations={tournamentState.tournament?.invitations ?? []}
                            queue={tournamentState.tournament?.queue ?? []}
                            onOpen={() => setWaitingListVisible(true)}
                            owner={tournamentState.tournament?.owner?.uid}
                          /> 
                        </div>
                        : null
                      }
                      {(statusPlannedStarted.includes(tournamentState.tournament?.status!) && !(isParticipatating || isQueued) && !hasBeenInvited) ? !tournamentState.tournament?.genders?.includes(current?.gender!) && !tournamentState.editMode ?
                        <div className={styles['invalid-gender']}>
                          Вы не можете стать участником турнира, этот турнир для {tournamentState.tournament?.genders?.includes(Gender.MAN) ? 'мужчин' : 'женщин'}
                        </div> : !tournamentState.tournament?.available ?
                        <div className={styles['invalid-rating']}>
                          <span>
                            Вы не можете стать участником турнира, этот турнир для игроков уровня {tournamentState.tournament?.displayMinGrade === tournamentState.tournament?.displayMaxGrade ?
                            <>{tournamentState.tournament?.displayMinGrade}</> :
                            <>{tournamentState.tournament?.displayMinGrade}...{tournamentState.tournament?.displayMaxGrade}</>
                          }
                          </span>
                          <div>Ваш уровень: <span className={styles['invalid-rating-value']}>{current?.displayGrade} | {current?.displayRating}</span></div>
                        </div> : null : null
                      }
                    </div>
                  }
                  <div style={{ height: `calc(${(offset) + 28}px + var(--sa-bottom))` }}></div>
                </>
              }
            </div>
            <div className={styles['buttons']} ref={buttonsRef}>
              <OversideWrapper className={styles['buttons-wrapper']}>
                {renderButtons}
              </OversideWrapper>
            </div>
          </BasePageWrapper>
        ) : null}
    </>
  );
}
 
export default TournamentPage;
