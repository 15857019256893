import { CalculatedGameStatus } from '@/integration-api/server-rest-lundapadelApi';
import styles from './GameStatusContent.module.css';
import Button, { ButtonVariants } from '@/components/Button/Button';
import { useAppSelector } from '@/hooks/hooks';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import CompletedGameSetCard from '../components/CompletedGameSetCard/CompletedGameSetCard';
import { Dispatch } from 'react';
import { canUserSetScore } from '../utils';
import { CompletedGameSubPage } from './GameStatusContent.interfaces';
import LineupCard from '@/components/LineupCard/LineupCard';
import LineupVersus from '@/components/LineupVersus/LineupVersus';
import MinitournamentContent from '../components/MinitournamentContent/MinitournamentContent';
import { IGamePageContext } from '../GamePage.interfaces';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

type CompletedGameContentProps = {
  subPage: CompletedGameSubPage;
  activeLineup?: string;
  setSubPage: Dispatch<CompletedGameSubPage>;
  setActiveLineup: Dispatch<string | undefined>;
}

const CompletedGameContent = ({ subPage, activeLineup, setSubPage, setActiveLineup }: CompletedGameContentProps) => {
  const { game, handlerLoading, initialLoading } = useOutletContext<IGamePageContext>();

  const current = useAppSelector(state => state.auth.currentProfile);
  const navigate = useNavigate();
  const { id } = useParams();

  let showBtn = subPage === 'game' && canUserSetScore(game, current?.identity?.uid!) && game?.status === CalculatedGameStatus.AFTER_GAME;

  const handleEditBtnClick = () => {
    if (game.condition?.locked) {
      PopupMessage.open('Игра редактируется дргуим пользователем.', 'error');
    } else {
      navigate(`/game/${id}/result`);
    }
  }

  const renderMainContent = () => {
    if(subPage === 'game') {
      if(game.gameTournamentKind) {
        return <MinitournamentContent tournament={game}/>
      } else {
        return (
          <div className={styles['match-sets']}>
            {game?.lineups?.filter(lineup => lineup.matches?.length || lineup.gameSets?.length).map?.(lineup => (
              <LineupCard
                key={lineup?.lineupUid}
                type={lineup?.gameSets?.length || lineup.matches?.length ? 'filled' : 'empty'}
                lineup={lineup}
                stickyBorderTop='-10px'
                onClick={() => {
                  setActiveLineup(lineup?.lineupUid);
                  setSubPage('lineup');
                }}
              />
            ))}
          </div>
        );
      }
    } else {
      const lineup = game?.lineups?.find?.(lp => lp?.lineupUid === activeLineup);
      return (
        <div className={styles['match-sets-detailed']}>
          <LineupVersus lineup={lineup ?? {}} linksAllowed/>
          {lineup?.matches?.map?.((match, i) => (
            <CompletedGameSetCard
              key={`match_${i}`}
              gameSets={match.gameSets ?? []}
              type='match'
            />
          ))}
          {lineup?.gameSets?.map((set, i) => (
            <CompletedGameSetCard
              key={`set_${i}`}
              gameSets={[set]}
              type='set'
            />
          ))}
        </div>
      );
    }
  }

  return (  
    <>
      <div className={styles['completed-wrapper']}>
        {renderMainContent()}
        {showBtn ?
          <div style={{ height: '84px' }}></div> : null
        }
      </div>
      {showBtn ?
        <OversideWrapper className={styles['buttons-wrapper-completed']}>
          <Button
            variant={ButtonVariants.BLUE}
            onClick={handleEditBtnClick}
            disabled={handlerLoading || initialLoading || !navigator.onLine}
          >
            Редактировать результаты игры
          </Button> 
        </OversideWrapper>
        : null
      }
    </>
  );
}
 
export default CompletedGameContent;
