import styles from '../GamePage.module.css';
import Header from "@/components/Header/Header";
import EditGameIcon from "@/static/images/icons/EditGameIcon";
import EntityCalendarIcon from "@/static/images/icons/EntityCalendarIcon";
import ShareIcon from "@/static/images/icons/ShareIcon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CalculatedGameStatus, GameTournamentStatus, PlayerProfileInfo, ReservationStatus, TicketType } from "@/integration-api/server-rest-lundapadelApi";
import { useAppSelector } from '@/hooks/hooks';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import formatter, { createImgSrc, ErrorUtils, getPlatform } from '@/utils/utils';
import { Share } from '@capacitor/share';
import PageBackground from '@/components/PageBackground/PageBackground';
import bgImage from '@/static/images/match-page-background.jpeg';
import PlannedGameContent from '../GameStatusContent/PlannedGameContent';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { Browser } from '@capacitor/browser';
import { IGamePageContext } from '../GamePage.interfaces';
import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import EntityWaitingListModal from '@/components/EntityWaitingListModal/EntityWaitingListModal';
import { useWebsocketTicket } from '../GameStatusContent/utils';
import { GameActions, HandleGameActionParams, GameModalsState } from '../GameStatusContent/GameStatusContent.interfaces';
import { useImmer } from 'use-immer';
import { acceptInviteForGame, bookingCancel, cancelGameInvite, createInviteForGame, createRequestChangeGameOwner, exitGame, gameCancel, shareGameMetric, startGameTournament } from '../service';
import GameActionModal from '../components/GameActionModal/GameActionModal';
import DetailedPageOptionExpantion from '@/components/DetailedPageOptionExpantion/DetailedPageOptionExpantion';
import { DetailedPageOptionExpantionOption } from '@/components/DetailedPageOptionExpantion/DetailedPageOptionExpantion.interfaces';
import CancelIcon from '@/static/images/icons/CancelIcon';
import ChatButton from '@/components/ChatButton/ChatButton';
import SwapOwner from '@/components/Icon/components/SwapOwner';
import ChangeOwnershipModal from '@/components/ChangeOwnershipModal/ChangeOwnershipModal';
import { acceptRequestChangeGameOwner } from '../../home/components/OwnershipChangeRequestsSection/service';

const PlannedGamePage = () => {
  const { game, gameChats, handlerLoading, goBackHandler, updateGame, setHandlerLoading } = useOutletContext<IGamePageContext>();
  const { id } = useParams();
  const current = useAppSelector(state => state.auth.currentProfile);
  const navigate = useNavigate();
  let webSocketURL = useWebsocketTicket(game.identity?.uid, TicketType.GAME);

  const [modalsState, setModalsState] = useImmer<GameModalsState>({
    leaveGameVisible: false,
    removeInviteVisible: false,
    cancelGameVisible: false,
    kickPlayerVisible: false,
    cancelBookingVisible: false,
    delegateOwnershipVisible: false,
  });

  const [waitingListVisible, setWaitingListVisible] = useState(false);
  const [changeOwnersipVisible, setChangeOwnersipVisible] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const activeReservation = useMemo(() => {
    return game.reservationInfos?.find(res => res.status !== ReservationStatus.CANCELED);
  }, [game.reservationInfos]);

  const shareGameHandler = useCallback(async () => {
    try {
      const { value } = await Share.canShare()
      if (value) {
        await Share.share({
          title: 'Присоединяйся к игре',
          text: `Корт: ${game?.club?.displayName ?? ''}, Дата: ${formatter.formatDateToDayDateMonth(game?.plannedDate)}`,
          url: `https://app.lundapadel.ru/share/game/${id}`
        });
        await shareGameMetric({ system: getPlatform() });
      } else {
        if (navigator.share !== undefined) {
          const shareData: ShareData = {
            title: 'Присоединяйся к игре',
            text: `Корт: ${game?.club?.displayName || ''}, Дата: ${formatter.formatDateToDayDateMonth(game?.plannedDate)}`,
            url: `${window.location.origin}/share${window.location.pathname}`,
          };
          await navigator.share(shareData);
          await shareGameMetric({ system: getPlatform() });
        } else {
          PopupMessage.open('Функционал не поддерживается');
        }
      }
    } catch(err) {
      console.log(err)
    }
  }, [game?.plannedDate, game?.club?.displayName, id]);

  const handleGameAction = useCallback(async (action: GameActions, params?: HandleGameActionParams) => {
    try {
      setHandlerLoading(state => { state.handler = true });
      switch(action) {
        case 'join': {
          await createInviteForGame({ gameUid: id, metrics: { system: getPlatform() }});
          setModalsState(state => { state.playerData = {} });
          if(!webSocketURL) {
            updateGame?.();
          }
          break;
        }
        case 'cancelRequest': {
          if(id) {
            await cancelGameInvite(id);
            if(!webSocketURL) {
              updateGame?.();
            }
          }
          break;
        }
        case 'acceptInvite': {
          await acceptInviteForGame({ accepted: true, gameUid: id, playerUid: params?.playerUid });
          setModalsState(state => {
            state.removeInviteVisible = false;
            state.playerData = undefined;
          });
          if(!webSocketURL) {
            updateGame?.();
          }
          break;
        }
        case 'removeInvite': {
          await acceptInviteForGame({ accepted: false, gameUid: id, playerUid: params?.playerUid });
          setModalsState(state => {
            state.removeInviteVisible = false;
            state.playerData = undefined;
          });
          if(!webSocketURL) {
            updateGame?.();
          }
          break;
        }
        case 'kick': {
          await exitGame({ gameUid: id, playerUid: params?.playerUid });
          setModalsState(state => {
            state.kickPlayerVisible = false;
            state.leaveGameVisible = false;
            state.playerData = undefined;
          });
          if(!webSocketURL) {
            updateGame?.();
          }
          break;
        }
        case 'cancelGame': {
          await gameCancel({ gameUid: id });
          setModalsState(state => {
            state.cancelGameVisible = false;
            state.playerData = undefined;
          });
          if(!webSocketURL) {
            updateGame?.();
          }
          break;
        }
        case 'start': {
          await startGameTournament({ gameUid: id });
          navigate(`/game/${id}/result`);
          break;
        }
        case 'cancelBooking': {
          await bookingCancel({ reservationUid: activeReservation?.uid });
          setModalsState(state => { state.cancelBookingVisible = false; });
          if(!webSocketURL) {
            updateGame?.();
          }
          break;
        }
        case 'delegateOwnership': {
          await createRequestChangeGameOwner({
            player: { uid: params?.playerUid },
            relatedEntityUid: id
          });
          setModalsState(state => {
            state.delegateOwnershipVisible = false;
            state.playerData = undefined;
          });
          setChangeOwnersipVisible(false);
          if(!webSocketURL) {
            updateGame?.();
          }
          break;
        }
        case 'handleOwnershipChange': {
          await acceptRequestChangeGameOwner({ accepted: params?.accepted, relatedEntityUid: id });
          if(!webSocketURL) {
            updateGame?.();
          }
          break;
        }
        default: {
          const _: never = action;
          throw new Error('not evety action is handled');
        }
      }
    } catch(err) {
      ErrorUtils.handleErrorMessage(err);
    } finally {
      setHandlerLoading(state => { state.handler = false });
    }
  }, [webSocketURL, id, modalsState, updateGame, activeReservation, game.members]);

  const renderCalendarButton = useMemo(() => {
    if(Capacitor.isNativePlatform()){
      return (
        <div className={styles['game-option']} onClick={async () => {
          try {
            if(Capacitor.getPlatform() === 'ios') {
              await Browser.open({
                url: `${createImgSrc.origin}/api/event/export?entityUid=${id}&entityType=GAME&type=&inline=true`,
                presentationStyle: 'popover'
              });
            } else {
              const { path } = await Filesystem.downloadFile({
                path: 'calendar.ics',
                directory: Directory.Data,
                url: `${createImgSrc.origin}/api/event/export?entityUid=${id}&entityType=GAME&type=&inline=true`,
              });
              if(path) {
                await FileOpener.open({
                  filePath: path
                });
              }
            }
          } catch(err) {
            PopupMessage.open('Произошла ошибка')
          }
        }}>
          <span>Добавить в календарь</span>
          <EntityCalendarIcon fill='black'/>
        </div>
      )
    } else {
      return (
        <a className={styles['game-option']} href={`${createImgSrc.origin}/api/event/export?entityUid=${id}&entityType=GAME&type=&inline=true`} download>
          <span>Добавить в календарь</span>
            <EntityCalendarIcon fill='black'/>
        </a> 
      )
    }
  }, [id]);

  const detailedPageOptions = useMemo(() => {
    const options: Array<DetailedPageOptionExpantionOption> = [];
    options.push({
      value: 'calendar',
      label: renderCalendarButton
    });
    options.push({
      value: 'share',
      label: (              
        <div
          className={styles['game-option']} 
          onClick={shareGameHandler}
        >
          Поделиться
          <ShareIcon fill='black'/>
        </div>
      )
    });
    if(game.owner?.uid === current.identity?.uid && game.status === CalculatedGameStatus.PLANNED) {
      options.push({
        value: 'delegate',
        label: (
          <div
            className={styles['game-option']}
            onClick={() => setChangeOwnersipVisible(true)}
          >
            Передать права
            <SwapOwner iconSize='middle' />
          </div>
        )
      })
    }
    if(game?.owner?.uid === current.identity?.uid && (game.status === CalculatedGameStatus.PLANNED || (game.status === CalculatedGameStatus.STARTED && game.tournamentStatus === GameTournamentStatus.EDITABLE))) {
      options.push({
        value: 'cancel',
        label: (
          <div
            className={styles['game-option']}
            style={{ color: '#FF3B30' }}
            onClick={() => setModalsState(state => { 
              state.cancelGameVisible = true;
              state.playerData = {
                booking: !!activeReservation
              };
            })}
          >
            Отменить игру
            <CancelIcon/>
          </div>
        )
      })
    }
    return options;
  }, [renderCalendarButton, modalsState.cancelGameVisible, modalsState.playerData, shareGameHandler, current.identity?.uid, game?.owner?.uid, game?.status, activeReservation]);

  const delegateOnwershipOptions = useMemo(() => {
    return game.members?.reduce<Array<PlayerProfileInfo>>((acc, curr) => {
      if(curr.player?.uid !== current.identity?.uid) {
        acc.push(curr.player ?? {});
      }
      return acc;    
    }, []) ?? [];
  }, [game.members]);

  useEffect(() => {
    let ignoreWSConnection = false;
    const socket = webSocketURL ? new WebSocket(webSocketURL) : null;

    if (socket) {
      socket.onopen = (e) => {
        if (ignoreWSConnection) {
          socket?.close();
        } else {
          console.log('Websocket is open!');
          if(intervalRef.current) {
            clearInterval(intervalRef.current);
          }
          intervalRef.current = setInterval(() => socket.send('ping'), 10000);
        }
      };
    
      socket.onerror = (error) => {
        console.log(error);
      };

      socket.onmessage = ({ data }) => {
        const { eventType } = JSON.parse(data);
        if (eventType === 'UPDATED') {
          updateGame();
        }
      };
    }
  
    return () => {
      if(intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (socket?.readyState === 1) {
        socket?.close();
      } else {
        ignoreWSConnection = true;
      }
    }
  }, [webSocketURL]);

  return (
    <>
      <GameActionModal
        type='kickPlayerVisible'
        visible={modalsState.kickPlayerVisible}
        handler={playerUid => handleGameAction('kick', { playerUid })}
        onClose={() => setModalsState(state => {state.kickPlayerVisible = false})}
        playerData={modalsState.playerData}
      />
      <GameActionModal
        type='removeInviteVisible'
        visible={modalsState.removeInviteVisible}
        handler={playerUid => handleGameAction('removeInvite', { playerUid })}
        onClose={() => setModalsState(state => {state.removeInviteVisible = false})}
        playerData={modalsState.playerData}
      />
      <GameActionModal
        type='leaveGameVisible'
        visible={modalsState.leaveGameVisible}
        handler={playerUid => handleGameAction('kick', { playerUid })}
        onClose={() => setModalsState(state => {state.leaveGameVisible = false})}
        playerData={modalsState.playerData}
      />
      <GameActionModal
        type='cancelGameVisible'
        visible={modalsState.cancelGameVisible}
        handler={() => handleGameAction('cancelGame')}
        onClose={() => setModalsState(state => { state.cancelGameVisible = false })}
        playerData={modalsState.playerData}
      />
      <GameActionModal
        type='cancelBookingVisible'
        visible={modalsState.cancelBookingVisible}
        handler={() => handleGameAction('cancelBooking')}
        onClose={() => setModalsState(state => { state.cancelBookingVisible = false })}
      />
      <GameActionModal
        type='delegateOwnershipVisible'
        visible={modalsState.delegateOwnershipVisible}
        handler={playerUid => handleGameAction('delegateOwnership', { playerUid })}
        onClose={() => setModalsState(state => { state.delegateOwnershipVisible = false })}
        playerData={modalsState.playerData}
      />
      <EntityWaitingListModal
        entity='game'
        visible={waitingListVisible}
        onClose={() => setWaitingListVisible(false)}
        invitations={game.invitations ?? []}
        owner={game.owner?.uid === current.identity?.uid}
        handleInviteAction={handleGameAction}
        loading={handlerLoading}
        deleteFromWaitingList={playerData => setModalsState(state => { 
          state.removeInviteVisible = true;
          state.playerData = playerData;
        })}
      /> 
      <ChangeOwnershipModal
        visible={changeOwnersipVisible}
        onClose={() => setChangeOwnersipVisible(false)}
        options={delegateOnwershipOptions}
        delegateOnwership={playerData => setModalsState(state => { 
          state.delegateOwnershipVisible = true;
          state.playerData = playerData;
        })}
      />
      {!(waitingListVisible || changeOwnersipVisible) ? (
          <BasePageWrapper showNavBar>
            <Header
              className={styles['header']}
              additional={
                <div className={styles['header-buttons']}>
                  {id ?                     
                    <ChatButton
                      enitity='game'
                      chats={gameChats}
                      id={id}
                    /> : null
                  }
                  {game?.owner?.uid === current.identity?.uid && game?.status === CalculatedGameStatus.PLANNED ?
                    <div className={styles['header-button']} onClick={() => navigate(`/game/${id}/settings`)}>
                      <EditGameIcon/>
                    </div> : null
                  }
                  <DetailedPageOptionExpantion
                    options={detailedPageOptions}
                  />
                </div>
              }
              handleClick={goBackHandler()}
            >
              Игра
            </Header>
            <PageBackground imgSrc={bgImage}/>
            <PlannedGameContent
              activeReservation={activeReservation}
              handelWaitingListVisible={state => setWaitingListVisible(state)}
              handleGameAction={handleGameAction}
              setModalsState={setModalsState}
            />
          </BasePageWrapper>
        ) : null}
    </>
  );
}
 
export default PlannedGamePage;
