import InnerPadding from '@/components/InnerPadding/InnerPadding';
import styles from './SelectCourtForBooking.module.css';
import Headling from '@/components/Headling/Headling';
import SelectCourt from '../SelectCourt/SelectCourt';
import CrossIcon from '@/static/images/icons/CrossIcon';

type SelectCourtForBookingProps = {
  availableCourts: Array<string>;
  selectedCourts: Array<string>;
  addCourt: (court: string) => void;
  deleteCourt: VoidFunction;
  selectCourt: (court: string, number: number) => void;
}

const SelectCourtForBooking = ({ availableCourts, selectedCourts, addCourt, deleteCourt, selectCourt }: SelectCourtForBookingProps) => {
  return (
    <div className={styles['wrapper']}>
      <InnerPadding>
        <Headling appearence='medium-normal' className={styles['headling']}>
          <span>Выберите корт</span>
          {availableCourts.length > 0 ? 
            <div className={styles['handle-courts']}>
              {selectedCourts.length > 1 ? 
                <span onClick={deleteCourt}>-  Убрать второй корт</span> :
                <>
                  {availableCourts.length - selectedCourts.length > 0 ? 
                    <>
                      <div>
                        <CrossIcon fill='var(--green-yellow)'/>
                      </div>
                      <span onClick={() => addCourt(availableCourts.filter(c => c !== selectedCourts?.[0])?.[0])}>Добавить корт</span>
                    </> : null
                  }
                </>
              }
            </div> : null
          }
        </Headling>
      </InnerPadding>
      <div className={styles['selects']}>
        {selectedCourts?.[0] ?        
          <SelectCourt
            number={0}
            options={availableCourts.filter(court => court !== selectedCourts?.[1])}
            selectedCourt={selectedCourts[0]}
            handleCourtSelect={selectCourt}
          /> : null
        }
        {selectedCourts?.[1] ?        
          <SelectCourt
            number={1}
            options={availableCourts.filter(court => court !== selectedCourts?.[0])}
            selectedCourt={selectedCourts[1]}
            handleCourtSelect={selectCourt}
          /> : null
        }
      </div>
    </div>
  );
}
 
export default SelectCourtForBooking;
