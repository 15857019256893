import { ConfirmationModalProps } from '@/components/ConfirmationModal/ConfirmationModal.interfaces';
import styles from './GameActionModal.module.css';
import { GameModalsPlayerData, GameModalsState } from '../../GameStatusContent/GameStatusContent.interfaces';
import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal';
import { useMemo } from 'react';
import ModalBallIcon from '@/static/images/icons/ModalBallIcon';
import ModalArrowIcon from '@/static/images/icons/ModalArrowIcon';

type GameActionModalProps = {
  type: keyof Omit<GameModalsState, 'playerData'>;
  playerData?: GameModalsPlayerData;
  handler: (agr?: string) => Promise<void>;
} & ConfirmationModalProps;

const GameActionModal = ({ handler, playerData, type, ...props }: GameActionModalProps) => {
  const renderModalContent = useMemo(() => {
    switch(type) {
      case 'removeInviteVisible': {
        return (
          <>
            <div className={styles['question']}>
              <span>Вы уверены, что хотите удалить <span style={{ color: 'var(--blue)'}}>{playerData?.displayName}</span> из списка приглашенных в эту игру?</span>
            </div>
            <div className={styles['description']}>
              Игрок не сможет присоединиться к игре по этому приглашению 
            </div>
            <button className={styles['button']} onClick={() => handler?.(playerData?.uid)}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Удалить игрока</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        )
      }
      case 'kickPlayerVisible': {
        return (
          <>
            <div className={styles['question']}>
              <span>Вы уверены, что хотите удалить <span style={{ color: 'var(--blue)'}}>{playerData?.displayName}</span> из игры?</span>
            </div>
            <div className={styles['description']}>
              Этот игрок получит уведомление и его место будет свободно
            </div>
            <button className={styles['button']} onClick={() => handler?.(playerData?.uid)}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Удалить игрока</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        )
      }
      case 'leaveGameVisible': {
        return (
          <>
            <div className={styles['question']}>
              Вы уверены, что хотите хотите покинуть игру?
            </div>
            <div className={styles['description']}>
              Если вы покинете место, остальные игроки получат уведомления 
            </div>
            <button className={styles['button']} onClick={() => handler?.(playerData?.uid)}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Покинуть игру</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        )
      }
      case 'cancelGameVisible': {
        return (
          <>
            <div className={styles['question']}>
              Вы уверены, что хотите отменить игру и снять бронирование?
            </div>
            <div className={styles['description']}>
              {playerData?.booking ? 
                <>
                  Для возврата средств необходимо предоставить чек об оплате по  номеру <a style={{ color: 'var(--blue)', textDecoration: 'underline' }} href='tel:+79154205000'>89154205000</a> (WhatsApp). 
                  <hr style={{ opacity: 0 }}/>
                  Денежные средства вернутся в течение 7 дней после отправки чека об оплате.
                </> : "Если вы отмените игру, остальные игроки получат уведомления"
              }
            </div>
            <button className={styles['button']} onClick={() => handler?.()}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Отменить игру</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        )
      }
      case 'cancelBookingVisible': {
        return (
          <>
            <div className={styles['question']}>
              Вы уверены, что хотите снять бронирование?
            </div>
            <div className={styles['description']}>
              Для возврата средств необходимо предоставить чек об оплате по  номеру <a style={{ color: 'var(--blue)', textDecoration: 'underline' }} href='tel:+79154205000'>89154205000</a> (WhatsApp). 
              <hr style={{ opacity: 0 }}/>
              Денежные средства вернутся в течение 7 дней после отправки чека об оплате.
            </div>
            <button className={styles['button']} onClick={() => handler?.()}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Снять бронирование</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        )
      }
      case 'delegateOwnershipVisible': {
        return (
          <>
            <div className={styles['question']}>
              <span>Отправить запрос на передачу прав <span style={{ color: 'var(--blue)'}}>{playerData?.displayName}</span>?</span>
            </div>
            <div className={styles['description']}>
              После принятия запроса все права на игру перейдут другому игроку 
            </div>
            <button className={styles['button']} onClick={() => handler?.(playerData?.uid)}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Отправить</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        )
      }
      default: {
        const _: never = type;
        throw new Error('not every type handeled')
      }
    }
  }, [type, handler, playerData?.displayName, playerData?.uid, playerData?.booking]);

  return (  
    <ConfirmationModal {...props}>
      {renderModalContent}
    </ConfirmationModal>
  );
}
 
export default GameActionModal;
