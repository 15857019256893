import styles from './FullScreenModal.module.css';
import { FullScreenModalProps } from './FullScreenModal.interfaces';
import { useRef, useState } from 'react';
import { useCapacitorKeyboard } from '@/hooks/useCapacitorKeyboard';

function FullScreenModal(props: FullScreenModalProps) {
  const { visible, onDone, title, children } = props;
  const [_, setTouched] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useCapacitorKeyboard(ref.current);

  return visible ? (
    <div className={styles['modal']} onTouchStart={() => setTouched(true)}>
      <div className={styles['inner-wrapper']} ref={ref}>
        <div className={styles['header']}>
          <h3>{title}</h3>
          <button className={styles['submit-btn']} onClick={onDone}>Готово</button>
        </div>
        <div className={styles['content']}>
          {children}
        </div>
      </div>
    </div>
  ) : null
}

export default FullScreenModal;
