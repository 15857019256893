import { useEffect, useMemo, useState } from 'react';
import styles from './SelectCourt.module.css';
import selectIcon from '@/static/images/icons/select-court-arrow-icon.svg'
import CourtIcon from '@/static/images/icons/CourtIcon';
import { AvailableCourt } from '@/integration-api/server-rest-lundapadelApi';
import { createImgSrc } from '@/utils/utils';
import InnerPadding from '@/components/InnerPadding/InnerPadding';

type SelectCourtProps = {
  number: number;
  options: Array<string>;
  selectedCourt: string;
  handleCourtSelect: (court: string, number: number) => void;
}

const SelectCourt = ({ number, options, selectedCourt, handleCourtSelect }: SelectCourtProps) => {
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const parsedSelectedCourt = useMemo(() => {
    try {
      return JSON.parse(selectedCourt) as AvailableCourt;
    } catch {
      return {};
    }
  }, [selectedCourt]);

  const renderOptions = useMemo(() => {
    return [selectedCourt].concat(options.filter(opt => opt !== selectedCourt)).map((option, index) => {
      let parsedOption:  AvailableCourt;
      try {
        parsedOption = (JSON.parse(option) as AvailableCourt);
      } catch(err) {
        parsedOption = {};
      }
      return (
        <div key={index} className={`${styles['option']} ${option === selectedCourt ? styles['labelChecked'] : ''}`}>
          <input 
            type="radio" 
            name={`court_${number}`} 
            id={`${number}_${options.length - index}`} 
            value={option} 
            onClick={() => {
              handleCourtSelect(option, number)
              setSelectOpen(false);
            }}
            style={{display: 'none'}}/>
          <label className={styles['label-style']} htmlFor={`${number}_${options.length - index}`}>
            <div className={styles['court-icon']}>
              <CourtIcon fill={option === selectedCourt ? 'var(--background-color)' : undefined}/>
            </div>
            <div className={styles['court-info']}>
              <span>{parsedOption.court?.caption}</span>
              <span className={option === selectedCourt ? styles['price-selected'] : styles['price']}>{parsedOption.price}₽</span>
            </div>
          </label>
        </div>
      )
    });
  }, [selectedCourt, options, number, handleCourtSelect]);
  
  const handleOptionsVisibility = (ev: MouseEvent) => {
    if(!((ev.target as HTMLElement).id === `fake_${number}` || (ev.target as HTMLInputElement)?.name?.includes(`court_${number}`)) && selectOpen) {
      setSelectOpen(false);
    }
  }
  
  useEffect(() => {
    document.addEventListener('click', handleOptionsVisibility);
    return () => {
      document.removeEventListener('click', handleOptionsVisibility);
    }
  }, [selectOpen]);

  return (
    <div className={styles['wrapper']}>
      <div className={styles['fake-select']}>
        <input 
          type="checkbox" 
          name={`fake_select_${number}`} 
          id={`fake_${number}`} 
          style={{display: 'none'}}
          onClick={() => setSelectOpen(state => !state)}/>
        <label htmlFor={`fake_${number}`} className={styles['fake-label']}>
          <div className={styles['fake-label-content']}>
            <div className={styles['court-icon']}>
              <CourtIcon/>
            </div>
            <div className={styles['court-info']} style={{ wordBreak: 'break-word' }}>
              <span>{parsedSelectedCourt.court?.caption}</span>
              <span className={styles['price']}>{parsedSelectedCourt.price}₽</span>
            </div>
          </div>
          <img src={selectIcon} alt="селект" className={styles['icon']}/>
        </label>
        <div className={styles['fake-options']} style={{ display: selectOpen ? 'flex' : 'none'}}>
          {renderOptions}
        </div>
      </div>    
      <div className={styles['additional-info']}>
        {parsedSelectedCourt.imageUid ? <img src={createImgSrc.court(parsedSelectedCourt.court?.uid, parsedSelectedCourt.imageUid)}/> : null}
        <InnerPadding>{parsedSelectedCourt.description}</InnerPadding>
      </div>
    </div>
  );
}

export default SelectCourt;
