import Icon, { IconProps } from "../Icon";

interface SignCircleProps extends IconProps {}

const SignCircle = ({ ...props }: SignCircleProps) => {
  return (  
    <Icon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20.8C14.3339 20.8 16.5722 19.8729 18.2225 18.2225C19.8729 16.5722 20.8 14.3339 20.8 12C20.8 9.66609 19.8729 7.42778 18.2225 5.77746C16.5722 4.12714 14.3339 3.2 12 3.2C9.66609 3.2 7.42778 4.12714 5.77746 5.77746C4.12714 7.42778 3.2 9.66609 3.2 12C3.2 14.3339 4.12714 16.5722 5.77746 18.2225C7.42778 19.8729 9.66609 20.8 12 20.8ZM10.828 14.558L16.637 8.75L17.485 9.599L11.535 15.549C11.3475 15.7365 11.0932 15.8418 10.828 15.8418C10.5628 15.8418 10.3085 15.7365 10.121 15.549L7 12.426L7.849 11.577L10.829 14.557L10.828 14.558Z" fill="#4EB857"/>
      </svg>
    </Icon>
  );
}
 
export default SignCircle;
