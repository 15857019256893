import { useAppSelector } from '@/hooks/hooks';
import { PlayerProfileInfo, PlayerProfileLoadListResult } from '@/integration-api/server-rest-lundapadelApi';
import { useEffect, useState } from 'react';
import { playerLoadList } from '../../service';
import PlayerItem from '@/components/PlayerItem/PlayerItem';
import { ItemType } from '@/components/PlayerItem/PlayerItem.interfaces';
import PlayerRating from '../PlayerRating/PlayerRating';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { RatingPlayersListProps } from './RatingPlayersList.interface';
import { parseSAValues } from '@/utils/utils';

export default function RatingPlayersList(props: RatingPlayersListProps) {
  const { searchParams, playerListWrapRef } = props;

  const currentUser = useAppSelector((state) => state.auth.currentProfile ?? {});

  const [playersList, setPlayersList] = useState<PlayerProfileLoadListResult>();

  useEffect(() => {
    // грейды в массиве лежат в обратном порядке, потому мин. грейд это - "grades[grades.length - 1]", а максимальный - "grades[0]"
    playerLoadList({...searchParams})
      .then(({result}) => {if (result) setPlayersList(result)})
      .catch((e) => console.log(e));
  }, [searchParams])


  const Row = ({ index, style }: any) => {
    if (playersList) {
      const player: PlayerProfileInfo = playersList.infos![index];
      return (
        <PlayerItem 
          id={player.uid}
          type={ItemType.RATING} 
          player={player} 
          playerPlacement={player.ratingPosition} 
          key={player.uid} 
          isActive={player.uid === currentUser.identity?.uid}
          customRating={<PlayerRating player={player} />}
          style={{...style}}
        />
    )} else {
      return null;
    };
  }

  return (
    <AutoSizer>
      {({width}: any) => (  
        playersList && (
        <List
          overscanCount={5}
          key={playersList.infos!.length}
          height={document.documentElement.clientHeight - 208 - parseSAValues()}
          itemCount={playersList.infos!.length}
          itemSize={57}
          width={width}
          className='players-list'
          // @ts-ignore
          ref={playerListWrapRef}         
        >
          {Row}
        </List>) as any
      )}
    </AutoSizer>
  )
}
