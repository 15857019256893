/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClubInfo,
    ClubInfoFromJSON,
    ClubInfoFromJSONTyped,
    ClubInfoToJSON,
} from './ClubInfo';
import {
    CourtInfo,
    CourtInfoFromJSON,
    CourtInfoFromJSONTyped,
    CourtInfoToJSON,
} from './CourtInfo';
import {
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeFromJSONTyped,
    LocalDateTimeToJSON,
} from './LocalDateTime';

/**
 * 
 * @export
 * @interface GameCreateSuggestInfo
 */
export interface GameCreateSuggestInfo {
    /**
     * 
     * @type {LocalDateTime}
     * @memberof GameCreateSuggestInfo
     */
    plannedDate?: LocalDateTime;
    /**
     * 
     * @type {number}
     * @memberof GameCreateSuggestInfo
     */
    duration?: number;
    /**
     * 
     * @type {ClubInfo}
     * @memberof GameCreateSuggestInfo
     */
    club?: ClubInfo;
    /**
     * 
     * @type {number}
     * @memberof GameCreateSuggestInfo
     */
    price?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof GameCreateSuggestInfo
     */
    ids?: Array<number>;
    /**
     * 
     * @type {Array<CourtInfo>}
     * @memberof GameCreateSuggestInfo
     */
    courts?: Array<CourtInfo>;
}

export function GameCreateSuggestInfoFromJSON(json: any): GameCreateSuggestInfo {
    return GameCreateSuggestInfoFromJSONTyped(json, false);
}

export function GameCreateSuggestInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameCreateSuggestInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'plannedDate': !exists(json, 'plannedDate') ? undefined : LocalDateTimeFromJSON(json['plannedDate']),
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'club': !exists(json, 'club') ? undefined : ClubInfoFromJSON(json['club']),
        'price': !exists(json, 'price') ? undefined : json['price'],
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
        'courts': !exists(json, 'courts') ? undefined : ((json['courts'] as Array<any>).map(CourtInfoFromJSON)),
    };
}

export function GameCreateSuggestInfoToJSON(value?: GameCreateSuggestInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plannedDate': LocalDateTimeToJSON(value.plannedDate),
        'duration': value.duration,
        'club': ClubInfoToJSON(value.club),
        'price': value.price,
        'ids': value.ids,
        'courts': value.courts === undefined ? undefined : ((value.courts as Array<any>).map(CourtInfoToJSON)),
    };
}

