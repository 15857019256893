import styles from './NotificationCard.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { FC, ReactNode, useCallback } from 'react';
import type { NotificationInfo } from '@/integration-api/server-rest-lundapadelApi/models/NotificationInfo';
import { NotificationRelatedEntityType } from '@/integration-api/server-rest-lundapadelApi/models/NotificationRelatedEntityType';
import type { NotificationCardContentProps, NotificationCardProps } from './NotificationCard.interfaces';
import ballIcon from '@/static/images/icons/green-ball-icon.svg';
import userIcon from '@/static/images/icons/add-user-icon.svg';
import { getNotificationRelativeTime, getNotificationTime } from './NotificationCardUtils';
import clockIcon from '@/static/images/icons/notification-clock-icon.svg';
import clockIconRead from '@/static/images/icons/notification-read-clock-icon.svg';
import locationIcon from '@/static/images/icons/notification-location-icon.svg';
import locationReadIcon from '@/static/images/icons/notification-read-location-icon.svg';
import RestClient from '@/integration/RestClient';
import { CalculatedGameStatus } from '@/integration-api/server-rest-lundapadelApi';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

const iconByType: Record<Partial<NotificationRelatedEntityType>, ReactNode> = {
  [NotificationRelatedEntityType.GAME]: (
    <div className={styles['icon-wrapper']}>
      <img src={ballIcon} alt="иконка мяча" />
    </div>
  ),
  [NotificationRelatedEntityType.MATCH]: (
    <div className={styles['icon-wrapper']}>
      <img src={ballIcon} alt="иконка мяча" />
    </div>
  ),
  [NotificationRelatedEntityType.TOURNAMENT]: (
    <div className={styles['icon-wrapper']}>
      <img src={ballIcon} alt="иконка турика" />
    </div>
  ),
  [NotificationRelatedEntityType.PLAYER_PROFILE]: (
    <div className={styles['icon-wrapper']}>
      <img src={userIcon} alt="иконка подписки" />
    </div>
  ),
  [NotificationRelatedEntityType.FEEDBACK]: (
    <div className={styles['icon-wrapper']}>
      <img src={userIcon} alt="иконка подписки" />
    </div>
  ),
  [NotificationRelatedEntityType.CHAT]: (
    <div className={styles['icon-wrapper']}>
      <img src={userIcon} alt="иконка подписки" />
    </div>
  ),
  [NotificationRelatedEntityType.SUBSCRIPTION]: undefined
};

const NotificationContentPlayer: FC<NotificationCardContentProps> = (props): JSX.Element => {
  const { notification } = props;
  const { description, timestamp, read } = notification;

  return (
    <div className={styles['content-wrapper-user']}>
      <Link to={`/profile/me`}>        
      <div className={styles['content']}>  
        <span>{description}</span>
          <div className={styles['status']}>
            {timestamp ?          
              <span style={{ whiteSpace: 'nowrap', color: read ? 'var(--gray-dark)' : 'var(--gray-blue)' }}>
                {getNotificationRelativeTime(timestamp)}
              </span> : null
            }
          </div>
        </div>
      </Link>
    </div>
  );
};

const NotificationContentTourgame: FC<NotificationCardContentProps> = (props): JSX.Element => {
  const { notification } = props;
  const { description, timestamp, read, clubName, plannedDate } = notification;
  const navigate = useNavigate();

  return (
    <div 
      className={styles['content-wrapper']} 
      onClick={() => navigate(notification.relatedEntityType === NotificationRelatedEntityType.GAME 
        ? `/game/${notification.relatedEntityUid}` 
        : `/tournament/${notification.relatedEntityUid}`)}
      >
      <div className={styles['content']}>  
        <span>{description}</span>
        <div className={styles['status']}>
          {!read ? iconByType[NotificationRelatedEntityType.MATCH] : null}
          {timestamp ?          
            <span style={{ whiteSpace: 'nowrap', color: read ? 'var(--gray-dark)' : 'var(--gray-blue)' }}>
              {getNotificationRelativeTime(timestamp)}
            </span> : null
          }
        </div>
      </div>
      <div className={styles['content-footer']}>
        {plannedDate ? 
          <div className={`${styles['time']} ${styles['read']}`}>
            <img src={read ? clockIconRead : clockIcon} alt='иконка часов'/>
            {getNotificationTime(plannedDate)}
          </div> : null
        }
        {clubName ?
          <div className={`${styles['time']} ${styles['read']}`}>
            <img src={read ? locationReadIcon : locationIcon} alt='иконка локации'/>
            <span>{clubName}</span>
          </div> : null
        }
      </div>
    </div>
  );
};

const NotificationContentFeedback: FC<NotificationCardContentProps> = (props) => {
  const { notification } = props;
  const { description, timestamp, read, relatedEntityType, relatedEntityUid } = notification;

  return (
    <div className={styles['content-wrapper-user']}>
      <Link to={relatedEntityType === NotificationRelatedEntityType.FEEDBACK ? `/profile/me/feedback` : `/subscriptions/${relatedEntityUid}/edit`}>        
      <div className={styles['content']}>  
        <span>{description}</span>
          <div className={styles['status']}>
            {timestamp ?          
              <span style={{ whiteSpace: 'nowrap', color: read ? 'var(--gray-dark)' : 'var(--gray-blue)' }}>
                {getNotificationRelativeTime(timestamp)}
              </span> : null
            }
          </div>
        </div>
      </Link>
    </div>
  )
}

const NotificationContentChat: FC<NotificationCardContentProps> = (props) => {
  const { notification } = props;
  const { description, timestamp, read, clubName, plannedDate } = notification;
  const navigate = useNavigate();

  const handleCardClick = () => {
    const chatUid = notification.relatedEntityUid;

    if (chatUid) {
      RestClient.mainApi.chatMessageLoadList({
        chatUid,
        limit: 0,
        offset: 0,
      })
        .then(({ result }) => {
          if (result?.active) {
            navigate(`/game/${result?.relatedEntityUid}/chat?uid=${chatUid}`);
          } else {
            PopupMessage.open('Чат закрыт', 'error');
          }
        })
        .catch(() => navigate('/'));
    }
  }

  return (
    <div 
      className={styles['content-wrapper']} 
      onClick={handleCardClick}
      >
      <div className={styles['content']}>  
        <span>{description}</span>
        <div className={styles['status']}>
          {!read ? iconByType[NotificationRelatedEntityType.MATCH] : null}
          {timestamp ?          
            <span style={{ whiteSpace: 'nowrap', color: read ? 'var(--gray-dark)' : 'var(--gray-blue)' }}>
              {getNotificationRelativeTime(timestamp)}
            </span> : null
          }
        </div>
      </div>
      <div className={styles['content-footer']}>
        {plannedDate ? 
          <div className={`${styles['time']} ${styles['read']}`}>
            <img src={read ? clockIconRead : clockIcon} alt='иконка часов'/>
            {getNotificationTime(plannedDate)}
          </div> : null
        }
        {clubName ?
          <div className={`${styles['time']} ${styles['read']}`}>
            <img src={read ? locationReadIcon : locationIcon} alt='иконка локации'/>
            <span>{clubName}</span>
          </div> : null
        }
      </div>
    </div>
  );
};

const renderNotificationContent = (notification: NotificationInfo) => {
  switch(notification.relatedEntityType) {
    case NotificationRelatedEntityType.GAME:
    case NotificationRelatedEntityType.TOURNAMENT:
      return <NotificationContentTourgame notification={notification} />;
    case NotificationRelatedEntityType.FEEDBACK: 
    case NotificationRelatedEntityType.SUBSCRIPTION:
      return <NotificationContentFeedback notification={notification}/>
    case NotificationRelatedEntityType.PLAYER_PROFILE:
      return <NotificationContentPlayer notification={notification} />;
    case NotificationRelatedEntityType.CHAT: 
      return <NotificationContentChat notification={notification} />
    default:
      return null;
  }
}

const NotificationCard: FC<NotificationCardProps> = (props): JSX.Element => {
  const { notification, first, onReadNotification } = props;

  const onClick = useCallback(() => {
    onReadNotification?.(notification.notificationUid);
  }, [notification.notificationUid, onReadNotification]);

  return (
    <div 
      style={{ color: notification.read ? 'var(--gray-dark)' : 'var(--white)' }}
      role="button" 
      onClick={onClick} 
      className={`${styles['card']} ${!first ? notification.read ? styles['border-read'] : styles['border-unread'] : ''}`}>
      {renderNotificationContent(notification)}
    </div>
  );
};

export default NotificationCard;
