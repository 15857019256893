/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AcceptInviteForTournamentRequest,
    AcceptInviteForTournamentRequestFromJSON,
    AcceptInviteForTournamentRequestToJSON,
    AcceptRequestChangeTournamentOwnerRequest,
    AcceptRequestChangeTournamentOwnerRequestFromJSON,
    AcceptRequestChangeTournamentOwnerRequestToJSON,
    CancelTournamentRequest,
    CancelTournamentRequestFromJSON,
    CancelTournamentRequestToJSON,
    CreateInviteListForTournamentRequest,
    CreateInviteListForTournamentRequestFromJSON,
    CreateInviteListForTournamentRequestToJSON,
    CreateRequestChangeTournamentOwnerRequest,
    CreateRequestChangeTournamentOwnerRequestFromJSON,
    CreateRequestChangeTournamentOwnerRequestToJSON,
    CreateTournamentRequest,
    CreateTournamentRequestFromJSON,
    CreateTournamentRequestToJSON,
    CreateTournamentResponse,
    CreateTournamentResponseFromJSON,
    CreateTournamentResponseToJSON,
    DeleteTournamentRoundRequest,
    DeleteTournamentRoundRequestFromJSON,
    DeleteTournamentRoundRequestToJSON,
    EntryTournamentRequest,
    EntryTournamentRequestFromJSON,
    EntryTournamentRequestToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    LoadListInviteRequest,
    LoadListInviteRequestFromJSON,
    LoadListInviteRequestToJSON,
    LoadListInviteResponse,
    LoadListInviteResponseFromJSON,
    LoadListInviteResponseToJSON,
    LoadListTournamentRoundResponse,
    LoadListTournamentRoundResponseFromJSON,
    LoadListTournamentRoundResponseToJSON,
    LoadTournamentResponse,
    LoadTournamentResponseFromJSON,
    LoadTournamentResponseToJSON,
    LoadTournamentRoundResponse,
    LoadTournamentRoundResponseFromJSON,
    LoadTournamentRoundResponseToJSON,
    LoadTournamentRoundsPreviewResponse,
    LoadTournamentRoundsPreviewResponseFromJSON,
    LoadTournamentRoundsPreviewResponseToJSON,
    LoadTournamentStatisticsResponse,
    LoadTournamentStatisticsResponseFromJSON,
    LoadTournamentStatisticsResponseToJSON,
    LoadTournamentTableResponse,
    LoadTournamentTableResponseFromJSON,
    LoadTournamentTableResponseToJSON,
    OwnerKickPlayerTournamentRequest,
    OwnerKickPlayerTournamentRequestFromJSON,
    OwnerKickPlayerTournamentRequestToJSON,
    OwnerKickPlayerTournamentResponse,
    OwnerKickPlayerTournamentResponseFromJSON,
    OwnerKickPlayerTournamentResponseToJSON,
    PastTourGameLoadListResponse,
    PastTourGameLoadListResponseFromJSON,
    PastTourGameLoadListResponseToJSON,
    RejectInviteForTournamentRequest,
    RejectInviteForTournamentRequestFromJSON,
    RejectInviteForTournamentRequestToJSON,
    RequestChangeOwnerLoadListRequest,
    RequestChangeOwnerLoadListRequestFromJSON,
    RequestChangeOwnerLoadListRequestToJSON,
    RequestChangeOwnerLoadListResponse,
    RequestChangeOwnerLoadListResponseFromJSON,
    RequestChangeOwnerLoadListResponseToJSON,
    SaveClassicTournamentTableRequest,
    SaveClassicTournamentTableRequestFromJSON,
    SaveClassicTournamentTableRequestToJSON,
    SaveClassicTournamentTableResponse,
    SaveClassicTournamentTableResponseFromJSON,
    SaveClassicTournamentTableResponseToJSON,
    SaveTournamentRequest,
    SaveTournamentRequestFromJSON,
    SaveTournamentRequestToJSON,
    SaveTournamentResponse,
    SaveTournamentResponseFromJSON,
    SaveTournamentResponseToJSON,
    SaveTournamentRoundRequest,
    SaveTournamentRoundRequestFromJSON,
    SaveTournamentRoundRequestToJSON,
    SaveTournamentRoundResponse,
    SaveTournamentRoundResponseFromJSON,
    SaveTournamentRoundResponseToJSON,
    SetPrivateTournamentRequest,
    SetPrivateTournamentRequestFromJSON,
    SetPrivateTournamentRequestToJSON,
    SetPrivateTournamentResponse,
    SetPrivateTournamentResponseFromJSON,
    SetPrivateTournamentResponseToJSON,
    StartTournamentRequest,
    StartTournamentRequestFromJSON,
    StartTournamentRequestToJSON,
    TournamentRoundCreateResponse,
    TournamentRoundCreateResponseFromJSON,
    TournamentRoundCreateResponseToJSON,
    UpdateTournamentRequest,
    UpdateTournamentRequestFromJSON,
    UpdateTournamentRequestToJSON,
    UpdateTournamentResponse,
    UpdateTournamentResponseFromJSON,
    UpdateTournamentResponseToJSON,
} from '../models';

export interface LundapadelTournamentApiAcceptInviteForTournamentOperationRequest {
    acceptInviteForTournamentRequest: AcceptInviteForTournamentRequest;
}

export interface LundapadelTournamentApiAcceptRequestChangeTournamentOwnerOperationRequest {
    acceptRequestChangeTournamentOwnerRequest: AcceptRequestChangeTournamentOwnerRequest;
}

export interface LundapadelTournamentApiCancelTournamentOperationRequest {
    cancelTournamentRequest: CancelTournamentRequest;
}

export interface LundapadelTournamentApiCreateInviteListForTournamentOperationRequest {
    createInviteListForTournamentRequest: CreateInviteListForTournamentRequest;
}

export interface LundapadelTournamentApiCreateRequestChangeTournamentOwnerOperationRequest {
    createRequestChangeTournamentOwnerRequest: CreateRequestChangeTournamentOwnerRequest;
}

export interface LundapadelTournamentApiCreateTournamentOperationRequest {
    createTournamentRequest: CreateTournamentRequest;
}

export interface LundapadelTournamentApiDeleteTournamentRoundOperationRequest {
    deleteTournamentRoundRequest: DeleteTournamentRoundRequest;
}

export interface LundapadelTournamentApiEntryTournamentOperationRequest {
    entryTournamentRequest: EntryTournamentRequest;
}

export interface LundapadelTournamentApiLoadListInviteOperationRequest {
    loadListInviteRequest: LoadListInviteRequest;
}

export interface LundapadelTournamentApiLoadListTournamentRoundRequest {
    tournamentUid: string;
}

export interface LundapadelTournamentApiLoadTournamentRequest {
    uid: string;
}

export interface LundapadelTournamentApiLoadTournamentRoundRequest {
    tournamentUid: string;
    roundNumber: number;
}

export interface LundapadelTournamentApiLoadTournamentRoundsPreviewRequest {
    tournamentUid: string;
}

export interface LundapadelTournamentApiLoadTournamentStatisticsRequest {
    tournamentUid: string;
}

export interface LundapadelTournamentApiLoadTournamentTableRequest {
    tournamentUid: string;
}

export interface LundapadelTournamentApiOwnerKickPlayerTournamentOperationRequest {
    ownerKickPlayerTournamentRequest: OwnerKickPlayerTournamentRequest;
}

export interface LundapadelTournamentApiPastTourGameLoadListRequest {
    playerUid: string;
    offset: number;
    limit: number;
}

export interface LundapadelTournamentApiRejectInviteForTournamentOperationRequest {
    rejectInviteForTournamentRequest: RejectInviteForTournamentRequest;
}

export interface LundapadelTournamentApiRequestChangeOwnerLoadListOperationRequest {
    requestChangeOwnerLoadListRequest: RequestChangeOwnerLoadListRequest;
}

export interface LundapadelTournamentApiSaveClassicTournamentTableOperationRequest {
    saveClassicTournamentTableRequest: SaveClassicTournamentTableRequest;
}

export interface LundapadelTournamentApiSaveTournamentOperationRequest {
    saveTournamentRequest: SaveTournamentRequest;
}

export interface LundapadelTournamentApiSaveTournamentRoundOperationRequest {
    saveTournamentRoundRequest: SaveTournamentRoundRequest;
}

export interface LundapadelTournamentApiSetPrivateTournamentOperationRequest {
    setPrivateTournamentRequest: SetPrivateTournamentRequest;
}

export interface LundapadelTournamentApiStartTournamentOperationRequest {
    startTournamentRequest: StartTournamentRequest;
}

export interface LundapadelTournamentApiTournamentFinishRequest {
    tournamentUid: string;
}

export interface LundapadelTournamentApiTournamentRoundCreateRequest {
    tournamentUid: string;
}

export interface LundapadelTournamentApiUpdateTournamentOperationRequest {
    updateTournamentRequest: UpdateTournamentRequest;
}

/**
 * 
 */
export class LundapadelTournamentApi extends runtime.BaseAPI {

    /**
     * Accept invite
     */
    async acceptInviteForTournamentRaw(requestParameters: LundapadelTournamentApiAcceptInviteForTournamentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.acceptInviteForTournamentRequest === null || requestParameters.acceptInviteForTournamentRequest === undefined) {
            throw new runtime.RequiredError('acceptInviteForTournamentRequest','Required parameter requestParameters.acceptInviteForTournamentRequest was null or undefined when calling acceptInviteForTournament.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invite/tournament/accept`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcceptInviteForTournamentRequestToJSON(requestParameters.acceptInviteForTournamentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Accept invite
     */
    async acceptInviteForTournament(requestParameters: LundapadelTournamentApiAcceptInviteForTournamentOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.acceptInviteForTournamentRaw(requestParameters, initOverrides);
    }

    /**
     * Accept request for change owner
     */
    async acceptRequestChangeTournamentOwnerRaw(requestParameters: LundapadelTournamentApiAcceptRequestChangeTournamentOwnerOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.acceptRequestChangeTournamentOwnerRequest === null || requestParameters.acceptRequestChangeTournamentOwnerRequest === undefined) {
            throw new runtime.RequiredError('acceptRequestChangeTournamentOwnerRequest','Required parameter requestParameters.acceptRequestChangeTournamentOwnerRequest was null or undefined when calling acceptRequestChangeTournamentOwner.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tournament/accept-request-change-owner`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcceptRequestChangeTournamentOwnerRequestToJSON(requestParameters.acceptRequestChangeTournamentOwnerRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Accept request for change owner
     */
    async acceptRequestChangeTournamentOwner(requestParameters: LundapadelTournamentApiAcceptRequestChangeTournamentOwnerOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.acceptRequestChangeTournamentOwnerRaw(requestParameters, initOverrides);
    }

    /**
     * Cancel tournament
     */
    async cancelTournamentRaw(requestParameters: LundapadelTournamentApiCancelTournamentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.cancelTournamentRequest === null || requestParameters.cancelTournamentRequest === undefined) {
            throw new runtime.RequiredError('cancelTournamentRequest','Required parameter requestParameters.cancelTournamentRequest was null or undefined when calling cancelTournament.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tournament/cancel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancelTournamentRequestToJSON(requestParameters.cancelTournamentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancel tournament
     */
    async cancelTournament(requestParameters: LundapadelTournamentApiCancelTournamentOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.cancelTournamentRaw(requestParameters, initOverrides);
    }

    /**
     * Create invite list for tournament by owner
     */
    async createInviteListForTournamentRaw(requestParameters: LundapadelTournamentApiCreateInviteListForTournamentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createInviteListForTournamentRequest === null || requestParameters.createInviteListForTournamentRequest === undefined) {
            throw new runtime.RequiredError('createInviteListForTournamentRequest','Required parameter requestParameters.createInviteListForTournamentRequest was null or undefined when calling createInviteListForTournament.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invite/tournament/create-invite-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateInviteListForTournamentRequestToJSON(requestParameters.createInviteListForTournamentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create invite list for tournament by owner
     */
    async createInviteListForTournament(requestParameters: LundapadelTournamentApiCreateInviteListForTournamentOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createInviteListForTournamentRaw(requestParameters, initOverrides);
    }

    /**
     * Create request for change owner
     */
    async createRequestChangeTournamentOwnerRaw(requestParameters: LundapadelTournamentApiCreateRequestChangeTournamentOwnerOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createRequestChangeTournamentOwnerRequest === null || requestParameters.createRequestChangeTournamentOwnerRequest === undefined) {
            throw new runtime.RequiredError('createRequestChangeTournamentOwnerRequest','Required parameter requestParameters.createRequestChangeTournamentOwnerRequest was null or undefined when calling createRequestChangeTournamentOwner.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tournament/create-request-change-owner`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRequestChangeTournamentOwnerRequestToJSON(requestParameters.createRequestChangeTournamentOwnerRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create request for change owner
     */
    async createRequestChangeTournamentOwner(requestParameters: LundapadelTournamentApiCreateRequestChangeTournamentOwnerOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createRequestChangeTournamentOwnerRaw(requestParameters, initOverrides);
    }

    /**
     * Create tournament
     */
    async createTournamentRaw(requestParameters: LundapadelTournamentApiCreateTournamentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateTournamentResponse>> {
        if (requestParameters.createTournamentRequest === null || requestParameters.createTournamentRequest === undefined) {
            throw new runtime.RequiredError('createTournamentRequest','Required parameter requestParameters.createTournamentRequest was null or undefined when calling createTournament.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tournament/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTournamentRequestToJSON(requestParameters.createTournamentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTournamentResponseFromJSON(jsonValue));
    }

    /**
     * Create tournament
     */
    async createTournament(requestParameters: LundapadelTournamentApiCreateTournamentOperationRequest, initOverrides?: RequestInit): Promise<CreateTournamentResponse> {
        const response = await this.createTournamentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete round for tournament
     */
    async deleteTournamentRoundRaw(requestParameters: LundapadelTournamentApiDeleteTournamentRoundOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deleteTournamentRoundRequest === null || requestParameters.deleteTournamentRoundRequest === undefined) {
            throw new runtime.RequiredError('deleteTournamentRoundRequest','Required parameter requestParameters.deleteTournamentRoundRequest was null or undefined when calling deleteTournamentRound.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tournament/round-delete`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteTournamentRoundRequestToJSON(requestParameters.deleteTournamentRoundRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete round for tournament
     */
    async deleteTournamentRound(requestParameters: LundapadelTournamentApiDeleteTournamentRoundOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteTournamentRoundRaw(requestParameters, initOverrides);
    }

    /**
     * Entry tournament
     */
    async entryTournamentRaw(requestParameters: LundapadelTournamentApiEntryTournamentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entryTournamentRequest === null || requestParameters.entryTournamentRequest === undefined) {
            throw new runtime.RequiredError('entryTournamentRequest','Required parameter requestParameters.entryTournamentRequest was null or undefined when calling entryTournament.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tournament/entry`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntryTournamentRequestToJSON(requestParameters.entryTournamentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Entry tournament
     */
    async entryTournament(requestParameters: LundapadelTournamentApiEntryTournamentOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.entryTournamentRaw(requestParameters, initOverrides);
    }

    /**
     * Load list invite
     */
    async loadListInviteRaw(requestParameters: LundapadelTournamentApiLoadListInviteOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadListInviteResponse>> {
        if (requestParameters.loadListInviteRequest === null || requestParameters.loadListInviteRequest === undefined) {
            throw new runtime.RequiredError('loadListInviteRequest','Required parameter requestParameters.loadListInviteRequest was null or undefined when calling loadListInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invite/load-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoadListInviteRequestToJSON(requestParameters.loadListInviteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadListInviteResponseFromJSON(jsonValue));
    }

    /**
     * Load list invite
     */
    async loadListInvite(requestParameters: LundapadelTournamentApiLoadListInviteOperationRequest, initOverrides?: RequestInit): Promise<LoadListInviteResponse> {
        const response = await this.loadListInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load list round for tournament
     */
    async loadListTournamentRoundRaw(requestParameters: LundapadelTournamentApiLoadListTournamentRoundRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadListTournamentRoundResponse>> {
        if (requestParameters.tournamentUid === null || requestParameters.tournamentUid === undefined) {
            throw new runtime.RequiredError('tournamentUid','Required parameter requestParameters.tournamentUid was null or undefined when calling loadListTournamentRound.');
        }

        const queryParameters: any = {};

        if (requestParameters.tournamentUid !== undefined) {
            queryParameters['tournamentUid'] = requestParameters.tournamentUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournament/round-load-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadListTournamentRoundResponseFromJSON(jsonValue));
    }

    /**
     * Load list round for tournament
     */
    async loadListTournamentRound(requestParameters: LundapadelTournamentApiLoadListTournamentRoundRequest, initOverrides?: RequestInit): Promise<LoadListTournamentRoundResponse> {
        const response = await this.loadListTournamentRoundRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load tournament
     */
    async loadTournamentRaw(requestParameters: LundapadelTournamentApiLoadTournamentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadTournamentResponse>> {
        if (requestParameters.uid === null || requestParameters.uid === undefined) {
            throw new runtime.RequiredError('uid','Required parameter requestParameters.uid was null or undefined when calling loadTournament.');
        }

        const queryParameters: any = {};

        if (requestParameters.uid !== undefined) {
            queryParameters['uid'] = requestParameters.uid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournament/load`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadTournamentResponseFromJSON(jsonValue));
    }

    /**
     * Load tournament
     */
    async loadTournament(requestParameters: LundapadelTournamentApiLoadTournamentRequest, initOverrides?: RequestInit): Promise<LoadTournamentResponse> {
        const response = await this.loadTournamentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load round for tournament
     */
    async loadTournamentRoundRaw(requestParameters: LundapadelTournamentApiLoadTournamentRoundRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadTournamentRoundResponse>> {
        if (requestParameters.tournamentUid === null || requestParameters.tournamentUid === undefined) {
            throw new runtime.RequiredError('tournamentUid','Required parameter requestParameters.tournamentUid was null or undefined when calling loadTournamentRound.');
        }

        if (requestParameters.roundNumber === null || requestParameters.roundNumber === undefined) {
            throw new runtime.RequiredError('roundNumber','Required parameter requestParameters.roundNumber was null or undefined when calling loadTournamentRound.');
        }

        const queryParameters: any = {};

        if (requestParameters.tournamentUid !== undefined) {
            queryParameters['tournamentUid'] = requestParameters.tournamentUid;
        }

        if (requestParameters.roundNumber !== undefined) {
            queryParameters['roundNumber'] = requestParameters.roundNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournament/round-load`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadTournamentRoundResponseFromJSON(jsonValue));
    }

    /**
     * Load round for tournament
     */
    async loadTournamentRound(requestParameters: LundapadelTournamentApiLoadTournamentRoundRequest, initOverrides?: RequestInit): Promise<LoadTournamentRoundResponse> {
        const response = await this.loadTournamentRoundRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load tournament rounds preview
     */
    async loadTournamentRoundsPreviewRaw(requestParameters: LundapadelTournamentApiLoadTournamentRoundsPreviewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadTournamentRoundsPreviewResponse>> {
        if (requestParameters.tournamentUid === null || requestParameters.tournamentUid === undefined) {
            throw new runtime.RequiredError('tournamentUid','Required parameter requestParameters.tournamentUid was null or undefined when calling loadTournamentRoundsPreview.');
        }

        const queryParameters: any = {};

        if (requestParameters.tournamentUid !== undefined) {
            queryParameters['tournamentUid'] = requestParameters.tournamentUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournament/load-rounds-preview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadTournamentRoundsPreviewResponseFromJSON(jsonValue));
    }

    /**
     * Load tournament rounds preview
     */
    async loadTournamentRoundsPreview(requestParameters: LundapadelTournamentApiLoadTournamentRoundsPreviewRequest, initOverrides?: RequestInit): Promise<LoadTournamentRoundsPreviewResponse> {
        const response = await this.loadTournamentRoundsPreviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load tournament statistics
     */
    async loadTournamentStatisticsRaw(requestParameters: LundapadelTournamentApiLoadTournamentStatisticsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadTournamentStatisticsResponse>> {
        if (requestParameters.tournamentUid === null || requestParameters.tournamentUid === undefined) {
            throw new runtime.RequiredError('tournamentUid','Required parameter requestParameters.tournamentUid was null or undefined when calling loadTournamentStatistics.');
        }

        const queryParameters: any = {};

        if (requestParameters.tournamentUid !== undefined) {
            queryParameters['tournamentUid'] = requestParameters.tournamentUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournament/statistics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadTournamentStatisticsResponseFromJSON(jsonValue));
    }

    /**
     * Load tournament statistics
     */
    async loadTournamentStatistics(requestParameters: LundapadelTournamentApiLoadTournamentStatisticsRequest, initOverrides?: RequestInit): Promise<LoadTournamentStatisticsResponse> {
        const response = await this.loadTournamentStatisticsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load tournament table
     */
    async loadTournamentTableRaw(requestParameters: LundapadelTournamentApiLoadTournamentTableRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadTournamentTableResponse>> {
        if (requestParameters.tournamentUid === null || requestParameters.tournamentUid === undefined) {
            throw new runtime.RequiredError('tournamentUid','Required parameter requestParameters.tournamentUid was null or undefined when calling loadTournamentTable.');
        }

        const queryParameters: any = {};

        if (requestParameters.tournamentUid !== undefined) {
            queryParameters['tournamentUid'] = requestParameters.tournamentUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournament/load-table`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadTournamentTableResponseFromJSON(jsonValue));
    }

    /**
     * Load tournament table
     */
    async loadTournamentTable(requestParameters: LundapadelTournamentApiLoadTournamentTableRequest, initOverrides?: RequestInit): Promise<LoadTournamentTableResponse> {
        const response = await this.loadTournamentTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancel tournament
     */
    async ownerKickPlayerTournamentRaw(requestParameters: LundapadelTournamentApiOwnerKickPlayerTournamentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OwnerKickPlayerTournamentResponse>> {
        if (requestParameters.ownerKickPlayerTournamentRequest === null || requestParameters.ownerKickPlayerTournamentRequest === undefined) {
            throw new runtime.RequiredError('ownerKickPlayerTournamentRequest','Required parameter requestParameters.ownerKickPlayerTournamentRequest was null or undefined when calling ownerKickPlayerTournament.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tournament/kick-player`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OwnerKickPlayerTournamentRequestToJSON(requestParameters.ownerKickPlayerTournamentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnerKickPlayerTournamentResponseFromJSON(jsonValue));
    }

    /**
     * Cancel tournament
     */
    async ownerKickPlayerTournament(requestParameters: LundapadelTournamentApiOwnerKickPlayerTournamentOperationRequest, initOverrides?: RequestInit): Promise<OwnerKickPlayerTournamentResponse> {
        const response = await this.ownerKickPlayerTournamentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load tournament for other player
     */
    async pastTourGameLoadListRaw(requestParameters: LundapadelTournamentApiPastTourGameLoadListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PastTourGameLoadListResponse>> {
        if (requestParameters.playerUid === null || requestParameters.playerUid === undefined) {
            throw new runtime.RequiredError('playerUid','Required parameter requestParameters.playerUid was null or undefined when calling pastTourGameLoadList.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling pastTourGameLoadList.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling pastTourGameLoadList.');
        }

        const queryParameters: any = {};

        if (requestParameters.playerUid !== undefined) {
            queryParameters['playerUid'] = requestParameters.playerUid;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tourgame/past-load-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PastTourGameLoadListResponseFromJSON(jsonValue));
    }

    /**
     * Load tournament for other player
     */
    async pastTourGameLoadList(requestParameters: LundapadelTournamentApiPastTourGameLoadListRequest, initOverrides?: RequestInit): Promise<PastTourGameLoadListResponse> {
        const response = await this.pastTourGameLoadListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Owner reject his invite
     */
    async rejectInviteForTournamentRaw(requestParameters: LundapadelTournamentApiRejectInviteForTournamentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.rejectInviteForTournamentRequest === null || requestParameters.rejectInviteForTournamentRequest === undefined) {
            throw new runtime.RequiredError('rejectInviteForTournamentRequest','Required parameter requestParameters.rejectInviteForTournamentRequest was null or undefined when calling rejectInviteForTournament.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invite/tournament/reject`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RejectInviteForTournamentRequestToJSON(requestParameters.rejectInviteForTournamentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Owner reject his invite
     */
    async rejectInviteForTournament(requestParameters: LundapadelTournamentApiRejectInviteForTournamentOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.rejectInviteForTournamentRaw(requestParameters, initOverrides);
    }

    /**
     * Request load list
     */
    async requestChangeOwnerLoadListRaw(requestParameters: LundapadelTournamentApiRequestChangeOwnerLoadListOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestChangeOwnerLoadListResponse>> {
        if (requestParameters.requestChangeOwnerLoadListRequest === null || requestParameters.requestChangeOwnerLoadListRequest === undefined) {
            throw new runtime.RequiredError('requestChangeOwnerLoadListRequest','Required parameter requestParameters.requestChangeOwnerLoadListRequest was null or undefined when calling requestChangeOwnerLoadList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tournament/list-request-change-owner`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestChangeOwnerLoadListRequestToJSON(requestParameters.requestChangeOwnerLoadListRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestChangeOwnerLoadListResponseFromJSON(jsonValue));
    }

    /**
     * Request load list
     */
    async requestChangeOwnerLoadList(requestParameters: LundapadelTournamentApiRequestChangeOwnerLoadListOperationRequest, initOverrides?: RequestInit): Promise<RequestChangeOwnerLoadListResponse> {
        const response = await this.requestChangeOwnerLoadListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save tournament table for classic kind
     */
    async saveClassicTournamentTableRaw(requestParameters: LundapadelTournamentApiSaveClassicTournamentTableOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SaveClassicTournamentTableResponse>> {
        if (requestParameters.saveClassicTournamentTableRequest === null || requestParameters.saveClassicTournamentTableRequest === undefined) {
            throw new runtime.RequiredError('saveClassicTournamentTableRequest','Required parameter requestParameters.saveClassicTournamentTableRequest was null or undefined when calling saveClassicTournamentTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tournament/save-classic-table`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveClassicTournamentTableRequestToJSON(requestParameters.saveClassicTournamentTableRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SaveClassicTournamentTableResponseFromJSON(jsonValue));
    }

    /**
     * Save tournament table for classic kind
     */
    async saveClassicTournamentTable(requestParameters: LundapadelTournamentApiSaveClassicTournamentTableOperationRequest, initOverrides?: RequestInit): Promise<SaveClassicTournamentTableResponse> {
        const response = await this.saveClassicTournamentTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save tournament
     */
    async saveTournamentRaw(requestParameters: LundapadelTournamentApiSaveTournamentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SaveTournamentResponse>> {
        if (requestParameters.saveTournamentRequest === null || requestParameters.saveTournamentRequest === undefined) {
            throw new runtime.RequiredError('saveTournamentRequest','Required parameter requestParameters.saveTournamentRequest was null or undefined when calling saveTournament.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tournament/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveTournamentRequestToJSON(requestParameters.saveTournamentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SaveTournamentResponseFromJSON(jsonValue));
    }

    /**
     * Save tournament
     */
    async saveTournament(requestParameters: LundapadelTournamentApiSaveTournamentOperationRequest, initOverrides?: RequestInit): Promise<SaveTournamentResponse> {
        const response = await this.saveTournamentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save round for tournament
     */
    async saveTournamentRoundRaw(requestParameters: LundapadelTournamentApiSaveTournamentRoundOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SaveTournamentRoundResponse>> {
        if (requestParameters.saveTournamentRoundRequest === null || requestParameters.saveTournamentRoundRequest === undefined) {
            throw new runtime.RequiredError('saveTournamentRoundRequest','Required parameter requestParameters.saveTournamentRoundRequest was null or undefined when calling saveTournamentRound.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tournament/round-save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveTournamentRoundRequestToJSON(requestParameters.saveTournamentRoundRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SaveTournamentRoundResponseFromJSON(jsonValue));
    }

    /**
     * Save round for tournament
     */
    async saveTournamentRound(requestParameters: LundapadelTournamentApiSaveTournamentRoundOperationRequest, initOverrides?: RequestInit): Promise<SaveTournamentRoundResponse> {
        const response = await this.saveTournamentRoundRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set tournament private
     */
    async setPrivateTournamentRaw(requestParameters: LundapadelTournamentApiSetPrivateTournamentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SetPrivateTournamentResponse>> {
        if (requestParameters.setPrivateTournamentRequest === null || requestParameters.setPrivateTournamentRequest === undefined) {
            throw new runtime.RequiredError('setPrivateTournamentRequest','Required parameter requestParameters.setPrivateTournamentRequest was null or undefined when calling setPrivateTournament.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tournament/set-private`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPrivateTournamentRequestToJSON(requestParameters.setPrivateTournamentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetPrivateTournamentResponseFromJSON(jsonValue));
    }

    /**
     * Set tournament private
     */
    async setPrivateTournament(requestParameters: LundapadelTournamentApiSetPrivateTournamentOperationRequest, initOverrides?: RequestInit): Promise<SetPrivateTournamentResponse> {
        const response = await this.setPrivateTournamentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Start tournament
     */
    async startTournamentRaw(requestParameters: LundapadelTournamentApiStartTournamentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.startTournamentRequest === null || requestParameters.startTournamentRequest === undefined) {
            throw new runtime.RequiredError('startTournamentRequest','Required parameter requestParameters.startTournamentRequest was null or undefined when calling startTournament.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tournament/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StartTournamentRequestToJSON(requestParameters.startTournamentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Start tournament
     */
    async startTournament(requestParameters: LundapadelTournamentApiStartTournamentOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.startTournamentRaw(requestParameters, initOverrides);
    }

    /**
     * Finish tournament
     */
    async tournamentFinishRaw(requestParameters: LundapadelTournamentApiTournamentFinishRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tournamentUid === null || requestParameters.tournamentUid === undefined) {
            throw new runtime.RequiredError('tournamentUid','Required parameter requestParameters.tournamentUid was null or undefined when calling tournamentFinish.');
        }

        const queryParameters: any = {};

        if (requestParameters.tournamentUid !== undefined) {
            queryParameters['tournamentUid'] = requestParameters.tournamentUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournament/finish`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Finish tournament
     */
    async tournamentFinish(requestParameters: LundapadelTournamentApiTournamentFinishRequest, initOverrides?: RequestInit): Promise<void> {
        await this.tournamentFinishRaw(requestParameters, initOverrides);
    }

    /**
     * Load list round for tournament
     */
    async tournamentRoundCreateRaw(requestParameters: LundapadelTournamentApiTournamentRoundCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TournamentRoundCreateResponse>> {
        if (requestParameters.tournamentUid === null || requestParameters.tournamentUid === undefined) {
            throw new runtime.RequiredError('tournamentUid','Required parameter requestParameters.tournamentUid was null or undefined when calling tournamentRoundCreate.');
        }

        const queryParameters: any = {};

        if (requestParameters.tournamentUid !== undefined) {
            queryParameters['tournamentUid'] = requestParameters.tournamentUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournament/round-create`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentRoundCreateResponseFromJSON(jsonValue));
    }

    /**
     * Load list round for tournament
     */
    async tournamentRoundCreate(requestParameters: LundapadelTournamentApiTournamentRoundCreateRequest, initOverrides?: RequestInit): Promise<TournamentRoundCreateResponse> {
        const response = await this.tournamentRoundCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update tournament
     */
    async updateTournamentRaw(requestParameters: LundapadelTournamentApiUpdateTournamentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UpdateTournamentResponse>> {
        if (requestParameters.updateTournamentRequest === null || requestParameters.updateTournamentRequest === undefined) {
            throw new runtime.RequiredError('updateTournamentRequest','Required parameter requestParameters.updateTournamentRequest was null or undefined when calling updateTournament.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tournament/update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTournamentRequestToJSON(requestParameters.updateTournamentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateTournamentResponseFromJSON(jsonValue));
    }

    /**
     * Update tournament
     */
    async updateTournament(requestParameters: LundapadelTournamentApiUpdateTournamentOperationRequest, initOverrides?: RequestInit): Promise<UpdateTournamentResponse> {
        const response = await this.updateTournamentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
