import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from '../pages/error/ErrorPage';
import HomePage from '../pages/application/home/HomePage';
import SignInPage from '@/pages/login/signIn/SignInPage';
import SignUpPage from '@/pages/login/signUp/SignUpPage';
import Forgot from '../pages/login/forgot/Forgot';
import ProfilePage from '../pages/application/profile/ProfilePage';
import NewsPage from '../pages/application/news/NewsPage';
import ClubsPage from '../pages/application/clubs/ClubsPage';
import RatingPage from '../pages/application/rating/RatingPage';
import ProfileViewPage from '../pages/application/profile/profileView/ProfileViewPage';
import ProfileEditPage from '../pages/application/profile/profileEdit/ProfileEditPage';
import ProfileChatPage from '../pages/application/profile/pofileChat/ProfileChatPage';
import InvitesPage from '@/pages/application/invites/InvitesPage';
import MyGamesPage from '@/pages/application/myGames/MyGamesPage';
import ProfilePartners from '@/pages/application/profile/profileView/profilePartners/ProfilePartners';
import ProfileFeedbackPage from '@/pages/application/profile/ProfileFeedback/ProfileFeedbackPage';
import RatingInfoPage from '@/pages/application/rating/RatingInfo/RatingInfoPage';
import CreatePage from '@/pages/application/create/CreatePage';
import TournamentCreatePage from '@/pages/application/create/tournamentCreate/TournamentCreatePage';
import TournamentPage from '@/pages/application/tournament/TournamentPage';
import BaseRoutingPath from '@/pages/application/baseRoutingPath/BaseRoutingPath';
import SharePage from '@/pages/application/share/SharePage';
import GameResultsPage from '@/pages/application/game/GameResults/GameResultsPage';
import TournamentResultsPage from '@/pages/application/tournament/TournamentResultsPage/TournamentResultsPage';
import RegisterPage from '@/pages/login/register/RegisterPage';
import WelcomePage from '../pages/login/welcome/WelcomePage';
import AuthPage from '@/pages/login/auth/AuthPage';
import GameCreatePage from '@/pages/application/create/GameCreate/GameCreatePage';
import GamePage from '@/pages/application/game/GamePage';
import GameInvitaionPage from '@/pages/application/game/GameInvitaionPage/GameInvitaionPage';
import PlayPage from '@/pages/application/play/PlayPage';
import GameJoinViaQrCode from '@/pages/application/game/GameJoinViaQrCode/GameJoinViaQrCode';
import ProfileGamesPage from '@/pages/application/profile/ProfileGamesPage/ProfileGamesPage';
import GameChatPage from '@/pages/application/game/GameChatPage/GameChatPage';
import ChatPage from '@/pages/application/chat/ChatPage';
import SubscriptionsPage from '@/pages/application/subscriptions/index/subscriptionsPage';
import CreateSubscriptionPage from '@/pages/application/subscriptions/create/createSubscriptionPage';
import EditSubscriptionPage from '@/pages/application/subscriptions/edit/editSubscriptionPage';
import ViewSubscriptionPage from '@/pages/application/subscriptions/view/viewSubscriptionPage';
import GamePageByCondition from '@/pages/application/game/GamePageByCondition/GamePageByCondition';
import ProfileReservationsPage from '@/pages/application/profile/ProfileReservations/ProfileReservationsPage';
import ProfileReservationPage from '@/pages/application/profile/ProfileReservation/ProfileReservationPage';
import RequestsPage from '@/pages/application/requests/RequestsPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <BaseRoutingPath />,
    errorElement: <ErrorPage />,
    children: [
      // Application
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'clubs/:clubName?',
        element: <ClubsPage />,
      },
      {
        path: 'rating',
        children: [
          {
            index: true,
            element: <RatingPage />
          },
          {
            path: 'info',
            element: <RatingInfoPage />
          },
        ]
      },
      {
        path: 'invites',
        element: <InvitesPage />
      },
      {
        path: 'requests',
        element: <RequestsPage />
      },
      {
        path: 'news/:id',
        element: <NewsPage />,
      },
      {
        path: 'my-games',
        element: <MyGamesPage />,
      },
      {
        path: 'share',
        children: [
          {
            path: 'match/:id',
            element: <SharePage />
          },
          {
            path: 'tournament/:id',
            element: <SharePage />
          },
          {
            path: 'game/:id',
            element: <SharePage />
          }
        ]
      },
      {
        path: 'tournament',
        children: [
          {
            path: ':id',
            children: [
              {
                index: true,
                element: <TournamentPage/>,
              },
              {
                path: 'invitation',
                element: <GameInvitaionPage />
              },
              {
                path: 'results',
                element: <TournamentResultsPage />
              },
              {
                path: 'settings',
                element: <CreatePage/>,
                children: [
                  {
                    index: true,
                    element: <TournamentCreatePage/>
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'create',
        element: <CreatePage/>,
        children: [
          {
            path: 'tournament',
            element: <TournamentCreatePage />,
          },
          {
            path: 'game',
            element: <GameCreatePage />,
          }
        ]
      },
      {
        path: 'play',
        element: <PlayPage />,
      },
      {
        path: 'profile',
        children: [
          {
            element: <ProfilePage />,
            children: [
              {
                path: ':id',
                children: [
                  {
                    index: true,
                    element: <ProfileViewPage />,
                  },
                  {
                    path: 'games',
                    element: <ProfileGamesPage/>,
                  },
                  {
                    path: 'edit',
                    element: <ProfileEditPage />,
                  },
                  {
                    path: 'feedback',
                    element: <ProfileFeedbackPage />,
                  },
                  {
                    path: 'chat',
                    element: <ProfileChatPage />,
                  },
                  {
                    path: 'partners',
                    element: <ProfilePartners />
                  },
                  {
                    path: 'reservations',
                    children: [
                      {
                        index: true,
                        element: <ProfileReservationsPage />
                      },
                      {
                        path: 'reservation',
                        element: <ProfileReservationPage/>
                      }
                    ]
                  }
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'game',
        element: <GamePage/>,
        children: [
          {
            path: ':id',
            children: [
              {
                index: true,
                element: <GamePageByCondition/>,
              },
              {
                path: 'chat',
                element: <GameChatPage />
              },
              {
                path: 'invitation',
                element: <GameInvitaionPage/>
              },
              {
                path: 'join-via-qrcode',
                element: <GameJoinViaQrCode/>
              },
              {
                path: 'result',
                element: <GameResultsPage/>
              },
              {
                path: 'settings',
                element: <CreatePage/>,
                children: [
                  {
                    index: true,
                    element: <GameCreatePage/>
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'chat',
        children: [
          {
            path: ':chatUid',
            element: <ChatPage />
          },
        ]
      },
      {
        path: 'subscriptions',
        children: [
          {
            index: true,
            element: <SubscriptionsPage />
          },
          {
            path: ':id',
            children: [
              {
                index: true,
                element: <ViewSubscriptionPage />
              },
              {
                path: 'edit',
                element: <EditSubscriptionPage />
              }
            ]
          },
          {
            path: 'create',
            element: <CreateSubscriptionPage />
          }
        ]
      }
    ],
  },
  //login
  {
    path: '/auth',
    element: <AuthPage />,
    children: [
      {
        index: true,
        element: <WelcomePage />,
      },
      {
        path: 'register',
        element: <RegisterPage />,
      },
      {
        path: 'sign-in',
        element: <SignInPage />,
      },
      {
        path: 'sign-up',
        element: <SignUpPage />,
      },
    ]
  },
  {
    path: '/forgot',
    element: <Forgot />,
  },
]);

export default router;
