import tempIgm from './temp.png'
import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import styles from './ProfileEditPage.module.css';
import Header from '@/components/Header/Header';
import UserPhotoBackground from '@/components/UserPhotoBackground/UserPhotoBackground';
import InputLabel from '@/components/InputLabel/InputLabel';
import Input from '@/components/Input/Input';
import DatePicker from '@/components/DatePicker/DatePicker';
import HLWrapper from '@/components/HLWrapper/HLWrapper';
import cameraIcon from '@/static/images/icons/camera-icon.svg';
import { useNavigate, useOutletContext } from 'react-router-dom';
import RadioButton from '@/components/RadioButton/RadioButton';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button, { ButtonVariants } from '@/components/Button/Button';
import AvatarEditor from 'react-avatar-editor';
import { ChangeEventHandler, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal';
import { useFormik } from 'formik';
import { useAppDispatch } from '@/hooks/hooks';
import { ClubView, CurrentPlayerProfileView, InviteRule, PlayerProfileAvatarSaveParameters, PlayerProfileView, Reference } from '@/integration-api/server-rest-lundapadelApi';
import { saveProfile } from '@/models/profile/thunks';
import { clubLoadList, deleteProfile, loadListClubCities, saveAvatar, saveFavoriteClubs, signOut, uploadAvatar } from '../service';
import { updateCurrentProfileThunk } from '@/models/auth/auth';
import { countries } from '@/static/images/countries/countries';
import formatter, { createImgSrc, declination, ErrorUtils } from '@/utils/utils';
import HLine from '@/components/HLine/HLine';
import { useImmer } from 'use-immer';
import { VisibleState } from './ProfileEditPage.interfaces';
import AutoSuggest from '@/components/Autosuggest/Autosuggest';
import CheckboxButton from '@/components/CheckboxButton/CheckboxButton';
import arrowInput from "@/static/images/icons/input-arrow.svg";
import ProfileFavoriteClubs from '../ProfileFavoriteClubs/ProfileFavoriteClubs';
import InfoPopup from '@/components/InfoPopup/InfoPopup';
import { unregisterNotifications } from '@/integration/Notifications';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import FullscreenLoader from '@/components/FullscreenLoader/FullscreenLoader';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

const wordArray: [string, string, string] = ['клуб', 'клуба', 'клубов'];

const ProfileEditPage: React.FC = () => {
  const [profile, setProfile] = useOutletContext<any>();
  const [profileIdentity, setProfileIdentity] = useState<Reference>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      ...profile
    },
    onSubmit: handleSubmitform,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validate: values => {
      const errors: typeof values = {};
      if(!values.details?.firstName) {
        Object.defineProperty(errors, 'details', { value: {
          firstName: 'Введите имя!'
        }});
        PopupMessage.open(errors.details?.firstName ?? '');
      }
      if(!values.details?.lastName) {
        Object.defineProperty(errors, 'details', { value: {
          lastName: 'Введите фамилию!'
        }});
        PopupMessage.open(errors.details?.lastName ?? '');
      }
      setErrors(errors);
      return errors;
    }
  });

  const [photoURL, setPhotoURL] = useState<any>('');
  const [modalVisible, setModalVisible] = useImmer<VisibleState>({crop: false, deleteProfile: false});
  const [recievedAvatar, setRecievedAvatar] = useState<string>();
  const [errors, setErrors] = useState<PlayerProfileView>({});
  const [accountManagement, setAccountManagement] = useState(false);
  const [cities, setCities] = useState<Array<string>>([]);
  const [favoriteClubsOpened, setFavoriteClubsOpened] = useState(false);
  const [clubs, setClubs] = useState<Array<ClubView>>([]);
  const [showFavClubsInfo, setShowFavClubsInfo] = useState<boolean>(false);
  const [isLoad, setIsLoad] = useState(false);

  const cropRef = useRef<AvatarEditor | null>(null);

  const favoriteClubs = clubs.filter(c => c.favorite);

  async function handleSubmitform(values: typeof profile) {
    try {
      const { payload } = await dispatch(
        saveProfile({
          ...values,
          identity: profileIdentity,
          details: {
            ...values?.details,
            birthday: new Date(values?.details?.birthday! || null)
          },
          displayName: `${values?.details?.lastName?.trim()} ${values?.details?.firstName?.trim()}`
        })
      ) as { payload: PlayerProfileView };
      if (payload) {
        const currentUpdate: CurrentPlayerProfileView = {
          avatarUid: payload.avatarUid,
          displayRating: payload.displayRating,
          displayGrade: payload.displayGrade,
          displayName: payload.displayName,
          grade: payload.grade,
          identity: payload.identity,
          nameInitials: payload.nameInitials,
          playerId: payload.playerId,
          city: payload.city
        };
        setProfile(payload)
        await dispatch(updateCurrentProfileThunk(currentUpdate));
        navigate(-1);
      }
    } catch (err) {
      ErrorUtils.handleErrorMessage(err);
    }
  }

  useEffect(() => {
    formik.setValues({ ...profile });
  }, [profile]);

  useEffect(() => {
    setRecievedAvatar(profile?.profileImageUid ? createImgSrc.playerProfile(profile?.identity?.uid, profile?.profileImageUid) : undefined);
  }, [profile?.profileImageUid, profile?.identity?.uid]);

  const handleLoadPhoto: ChangeEventHandler<HTMLInputElement> = async (e) => {
    try {
      if (e.target.files?.[0]) {
        setPhotoURL(e.target.files[0]);
        setModalVisible(draft => {draft.crop = true});
      }
      formik.handleChange(e);
    } catch(err) {
      ErrorUtils.handleErrorMessage(err);
    }
  }

  const handleSubmitPhoto = async () => {
    setIsLoad(true);

    const avatarSaveParameters: PlayerProfileAvatarSaveParameters = {
      height: 40,
      width: 40
    } 
    const canvas = cropRef.current ? cropRef.current.getImageScaledToCanvas() : null;
    canvas?.toBlob(bl => {
      if(bl) {
        uploadAvatar(bl)
          .then(({ result: res }) => {
            return saveAvatar(res?.temporalObjectUid ?? '', profile ?? {}, avatarSaveParameters);
          })
          .then(({ result }) => {
            const currentUpdate: CurrentPlayerProfileView = {
              avatarUid: result?.avatarUid,
              displayRating: result?.displayRating,
              displayGrade: result?.displayGrade,
              displayName: result?.displayName,
              grade: result?.grade,
              identity: result?.identity,
              nameInitials: result?.nameInitials,
              playerId: result?.playerId,
              city: result?.city,
              email: result?.email
            };
            setProfile(result)
            dispatch(updateCurrentProfileThunk(currentUpdate));
            if(result) {
              setRecievedAvatar(result.profileImageUid ? createImgSrc.playerProfile(result.identity?.uid, result.profileImageUid) : undefined);
            }
            setModalVisible(draft => {draft.crop = false});
          })
          .catch(err => ErrorUtils.handleErrorMessage(err))
          .finally(() => setIsLoad(false))
        }
      })
  }

  const handleSignout = () => {
    signOut()
    .then(() => {
      localStorage.removeItem('fb-token');
      navigate('/auth');
      dispatch({ type: 'reset/all' });
    })
    .catch((err) => ErrorUtils.handleErrorMessage(err))
    .finally(() => setIsLoad(false))
  }

  const handleSignOutBtnClick = () => {
    const token = localStorage.getItem('fb-token');
    setIsLoad(true)
    if(token && Capacitor.isPluginAvailable("PushNotifications")) {
      unregisterNotifications(token)
        .then(() => {
          handleSignout();
        })
        .catch(err => ErrorUtils.handleErrorMessage(err))
        .finally(() => setIsLoad(false));
    } else {
      handleSignout();
    }
  }

  const handleDeleteProfile = () => {
    setIsLoad(true)
    deleteProfile()
      .then(() => {
        if(Capacitor.isPluginAvailable("PushNotifications")) {
          PushNotifications.unregister()
        }
        localStorage.removeItem('fb-token');
        navigate('/auth');
        dispatch({ type: 'reset/all' });
      })
      .catch((err) => {
        ErrorUtils.handleErrorMessage(err);
      })
      .finally(() => setIsLoad(false));
  }

  const updateClubs = useCallback((clubs: Array<ClubView>) => {
    const favClubsRefs: Array<Reference> = clubs.map(fc => fc.reference ?? {});
    saveFavoriteClubs({ clubs: favClubsRefs, identity: profileIdentity })
      .then(({ result }) => {
        setProfileIdentity(result?.identity ?? {});
        setFavoriteClubsOpened(false);
      })
      .catch(err => ErrorUtils.handleErrorMessage(err))
  }, [profileIdentity]);

  useEffect(() => {
    setProfileIdentity({ ...profile?.identity });
    loadListClubCities()
      .then(({ result }) => {
        setCities(result?.cities ?? []);
      })
      .catch(err => ErrorUtils.handleErrorMessage(err))
  }, []);

  useEffect(() => {
    clubLoadList({ favorite: true })
      .then(({ result }) => {
        setClubs(result?.views ?? []);
      })
      .catch(err => ErrorUtils.handleErrorMessage(err))
  }, [profileIdentity]);

  useEffect(() => {
    if(window.location.search === '?favoriteClubs') {
      setFavoriteClubsOpened(true);
    }
  }, []);

  const closePopup = (e: MouseEvent) => {
    if ((e.target as HTMLDivElement).id !== 'favClubsInfo') {
      setShowFavClubsInfo(false)
    }
  }

  return (
    <BasePageWrapper contentWrapperClassName={styles.contentWrap}>
      {!accountManagement && !favoriteClubsOpened ?
        <>
        
          <UserPhotoBackground bgSrc={recievedAvatar || tempIgm} />
          <Header>Редактирование профиля</Header>

          <form
            onSubmit={formik.handleSubmit}
            className={styles.form}
            onClick={closePopup}
          >
            <label className={styles.cameraLabel}>
              <img className={styles.cameraImg} src={cameraIcon} alt="Камера" />
              <input
                className={styles.cameraInput}
                type='file'
                accept='.png, .jpg, .jpeg'
                onChange={handleLoadPhoto}
                name='photo'
              />
            </label>

            <InputLabel label='Имя'>
              <HLWrapper fieldsErrors={!!errors.details?.firstName}>
                <Input 
                  placeholder="Ваше имя" 
                  name='details.firstName' 
                  autoComplete='off'
                  onChange={formik.handleChange} 
                  value={formik.values.details?.firstName ?? ''} 
                  enterKeyHint='done'
                  style={{ color: 'var(--gray-light)'}}/> 
              </HLWrapper>
            </InputLabel>

            <InputLabel label='Фамилия'>
              <HLWrapper fieldsErrors={!!errors.details?.lastName}>
                <Input
                  placeholder="Ваша фамилия" 
                  name='details.lastName' 
                  autoComplete='off'
                  onChange={formik.handleChange} 
                  value={formik.values.details?.lastName ?? ''} 
                  enterKeyHint='done'
                  style={{ color: 'var(--gray-light)'}}/> 
              </HLWrapper>
            </InputLabel>

            <InputLabel label='Дата рождения'>
              <HLWrapper>
                <DatePicker name='details.birthday' date={formik.values.details?.birthday} onChange={formik.handleChange} style={{ color: 'var(--gray-light)' }}/>
              </HLWrapper>
            </InputLabel>

            <InputLabel label='Телефон'>
              <HLWrapper>
                <div className={styles.phoneField}>
                  <div className={styles.phoneValue}>
                    <img
                      src={countries.ru.img}
                      alt="Coutry"
                      className={styles.phoneSelectCoutry}
                    />
                    <span style={{ alignSelf: 'flex-end' }}>+{profile?.countryCode}</span>
                    <span style={{ alignSelf: 'flex-end' }}>{formatter.formatRuPhone(profile?.phone)}</span>
                  </div>
                </div>
              </HLWrapper>
            </InputLabel>

            <InputLabel label="Предпочтительный город для игры">
              <div className={styles.inputWrapper}>
                <AutoSuggest 
                  values={cities} 
                  defaultSuggestValue={profile?.city ?? ''} 
                  onInputChange={(val) => formik.setFieldValue('city', val)} 
                  placeholder='Укажите ваше местоположение'
                />
              </div>
            </InputLabel>

            <InputLabel 
              label={
                <div className={styles['favorite-clubs-label']}>
                  <span>
                    Любимый клуб
                  </span>
                  <InfoPopup
                    content='Ваши любимые клубы будут отображаться в быстром доступе на главной странице, а также в создании матча'
                    id='favClubsInfo'
                    showInfo={showFavClubsInfo}
                    setShowInfo={setShowFavClubsInfo}
                    width={255}
                    right ={-115}
                    arrowOffset={118}
                  />
                </div>
              }
            >
              <div className={styles['favorite-clubs-button']} onClick={() => setFavoriteClubsOpened(true)}>
                {favoriteClubs.length ?                 
                  <div className={styles['favorite-club-info-wrapper']}>
                    <span className={styles['favorite-club-name']}>{favoriteClubs[favoriteClubs.length - 1].name}</span>
                    <span className={styles['favorite-club-location']}>{favoriteClubs[favoriteClubs.length - 1].mapAddress}</span>
                    {favoriteClubs.length > 1 ?
                      <span className={styles['favorite-club-rest']}>
                        И еще + {favoriteClubs.length - 1} {declination(favoriteClubs.length - 1, wordArray)}
                      </span> : null
                    }
                  </div> : 
                  <span className={styles['favorite-club-select']}>Выбрать любые клубы</span>
                }
                <img src={arrowInput} alt='стрелка ввести' style={{ transform: 'rotate(-90deg)' }}/>
              </div>
            </InputLabel>

            <InputLabel label='Телеграм'>
              <HLWrapper>
                <Input
                  prefixIcon={formik.values.telegramUsername && <span className={styles.telegramInputPrefix}>@</span>}
                  placeholder="Username"
                  name='telegramUsername'
                  onChange={formik.handleChange}
                  value={formik.values.telegramUsername?.replaceAll('@', '')}
                  enterKeyHint='done'
                /> 
              </HLWrapper>
            </InputLabel>

            <InputLabel label='Электронная почта'>
              <HLWrapper>
                <Input
                  placeholder="Введите вашу электронную почту"
                  name='email'
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  enterKeyHint='done'
                /> 
              </HLWrapper>
            </InputLabel>

            <div className={styles.firstRadio}>
              <InputLabel label="Предпочтительный квадрат">
                <HLWrapper>
                  <div className={styles.radioWrapper}>
                    <CheckboxButton label="Левый" name='leftSide' checked={formik.values?.leftSide ?? false} onChange={formik.handleChange} />
                    <CheckboxButton label="Правый" name='rightSide' checked={formik.values?.rightSide ?? false} onChange={formik.handleChange} />
                  </div>
                </HLWrapper>
              </InputLabel>
            </div>

            <InputLabel label='Кто может приглашать меня в игру'>
              <div className={`${styles.radioWrap} ${styles.privateSettings}`}>
                <RadioButton label='Все игроки могут приглашать меня' checked={formik.values.inviteRule === InviteRule.ALL} name='inviteRule' value={InviteRule.ALL} onChange={formik.handleChange} />
                <RadioButton label='Только напарники' checked={formik.values.inviteRule === InviteRule.PARTNERS_ONLY} name='inviteRule' value={InviteRule.PARTNERS_ONLY} onChange={formik.handleChange} />
                <RadioButton label='Никто' checked={formik.values.inviteRule === InviteRule.NOBODY} name='inviteRule' value={InviteRule.NOBODY} onChange={formik.handleChange} />
              </div>
            </InputLabel>

            <HLine calssName={styles.hlHeight}/>

            <InputLabel label='Аккаунт'>
              <div 
                className={styles['account-management']}
                onClick={() => setAccountManagement(true)}
              >
                <span>Управление аккаунтом</span>
                <img src={arrowInput} alt='стрелка ввести' style={{ transform: 'rotate(-90deg)' }}/>
              </div>
            </InputLabel>

            <OversideWrapper className={styles.saveBtm}>
              <Button>Сохранить</Button>
            </OversideWrapper>
          </form>
        </> : null
      } 
      {accountManagement ? 
        <>
          <Header
            handleClick={() => setAccountManagement(false)}
          >
            Управление аккаунтом
          </Header>
          <div className={styles.radioWrap}>
            <Button type='button' onClick={handleSignOutBtnClick} className={styles.signOutBtn} variant={ButtonVariants.CANCEL} bordered>
              Выйти из аккаунта
            </Button>

            <Button type='button' onClick={() => setModalVisible(draft => {draft.deleteProfile = true})} className={styles.deleteBtn} bordered>
              Удалить аккаунт
            </Button>
          </div>
        </> : null
      }
      {favoriteClubsOpened ? 
        <ProfileFavoriteClubs
          favoriteClubs={clubs}
          onClose={() => setFavoriteClubsOpened(false)}
          updateClubs={updateClubs}
        /> : null
      }

      <ConfirmationModal visible={modalVisible.crop} onClose={() => setModalVisible(draft => {draft.crop = false})}>
        <div className={styles.modalContent}>
          Фото профиля
          <AvatarEditor
            image={photoURL}
            width={512}
            height={512}
            scale={1.2}
            // rotate={45}
            ref={cropRef}
          />
        </div>
        <Button onClick={handleSubmitPhoto}>Ок</Button>
      </ConfirmationModal>

      <ConfirmationModal visible={modalVisible.deleteProfile} onClose={() => setModalVisible(draft => {draft.deleteProfile = false})}>
        <div className={styles.modalContent}>
          Вы уверены, что хотите удалить аккаунт?
        </div>
        <Button onClick={handleDeleteProfile}>Ок</Button>
      </ConfirmationModal>

      <FullscreenLoader loading={isLoad} />
    </BasePageWrapper>
  );
}

export default ProfileEditPage;
