import InnerPadding from '@/components/InnerPadding/InnerPadding';
import styles from './BookingPaymentPage.module.css';
import Header from "@/components/Header/Header";
import Headling from '@/components/Headling/Headling';
import CourtIcon from '@/static/images/icons/CourtIcon';
import LocationIconOutline from '@/static/images/icons/LocationIconOutline';
import CalendarIcon from '@/static/images/icons/CalendarIcon';
import { Map, Placemark } from '@pbe/react-yandex-maps';
import { FormikErrors, useFormik } from 'formik';
import Input from '@/components/Input/Input';
import { BookingPaymentLoadingState, BookingPaymentPageProps, PaymentFormValues } from './BookingPaymentPage.interfaces';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button from '@/components/Button/Button';
import { bookingCreate, createYookassaPayment, getEnableStatusYookassa, paymentAmountCalculate } from '../service';
import { toLocalDate } from '@/integration-api/server-rest-lundapadelApi';
import formatter, { ErrorUtils } from '@/utils/utils';
import { calculateEndTime } from '../components/MatchResultSettings/utils';
import { AvailableCourt } from '@/integration-api/server-rest-get-info';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CheckboxSquare from '@/components/CheckboxSquare/CheckboxSquare';
import { applyEmailCheckboxStyle, defaultYMapState, initializeYooMoneyWidget } from './utils';
import HLine from '@/components/HLine/HLine';
import { useAppDispatch } from '@/hooks/hooks';
import { updateCurrentProfileThunk } from '@/models/auth/auth';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import { saveProfileEmail } from '../../game/service';
import { handleGame } from '../utils';
import { useImmer } from 'use-immer';
import FullscreenLoader from '@/components/FullscreenLoader/FullscreenLoader';

const BookingPaymentPage = ({ formValues, current, paymentData, setPaymentData, onClose }: BookingPaymentPageProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [newGameId, setNewGameId] = useState<string>();

  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [errorField, setErrorField] = useState<string>();
  const [loadingState, setLoadingState] = useImmer<BookingPaymentLoadingState>({
    gameEntities: false,
    paymentEntities: false,
  })

  const paymentForm = useFormik<PaymentFormValues>({
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validate: values => {
      const errors: FormikErrors <PaymentFormValues> = {};
      if(!values.email)  {
        errors.email = 'Введите электронную почту!';
        setErrorField('email');
        PopupMessage.open(errors.email);
      }
      if(values.email && !values.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
        errors.email = 'Введите валидную электронную почту!';
        setErrorField('email');
        PopupMessage.open(errors.email);
      }
      return errors;
    },
    initialValues: {
      name: current.displayName ?? '',
      checkboxEmail: !!current.email,
      email: current.email ?? ''
    },
    onSubmit: handlePayment
  });

  async function handlePayment() {
    if(!paymentData.confirmationToken && !paymentData.paymentUid) {
      try {
        const { email, name, checkboxEmail } = paymentForm.values;
        setLoadingState(state => { state.gameEntities = true });
        //Проверяем, доступен ли сервис youkassa
        const { result: yookassaStatusResult } = await getEnableStatusYookassa();
        if(yookassaStatusResult?.enabled) {
          //сохраняем email, если включен чекбокс и это какой-то новый адресс
          if(checkboxEmail && (email !== current.email)) {
            await saveProfileEmail({ email });
            dispatch(updateCurrentProfileThunk({ ...current, email }));
          }
          //создание\обновление игры
          const gameId = await handleGame({
            current,
            type: id ? 'edit' : 'create',
            values: { ...formValues, courtBooked: false }
          });
          if(gameId) {
            //сохраняем id игры, если создали новую
            setNewGameId(gameId);
            let bookingResult;
            try {
              const { club, plannedDate, selectedCourts, duration } = formValues;
              const courts = selectedCourts.map(court => {
                try {
                  return (JSON.parse(court) as AvailableCourt)?.court ?? {};
                } catch (error) {
                  return {};
                }
              });
              //создание бронировани 
              const { result } = await bookingCreate({
                club: club?.reference,
                date: toLocalDate(plannedDate.date),
                time: dayjs(plannedDate.time, 'H:mm').format('HH:mm:ss'),
                fullName: name,
                relatedEntityUid: gameId,
                email,
                courts,
                duration: Number(duration.replace(',', '.')) * 60
              });
              //если успешно, то записываем в переменную результат. В случае эксепшена уходим в catch, где происходит редирект
              bookingResult = result;
            } catch(err) {
              ErrorUtils.handleErrorMessage(err);
              if(id) {
                navigate(`/game/${id}/?afterCreation`);
              } else {
                navigate(`/game/${gameId}/?afterCreation`)
              }
            }
            //если бронирование успешно, создаем платеж yookassa, который необходим для инициализации виджета оплаты
            if(bookingResult) {
              const { reservationUid } = bookingResult
              setLoadingState(state => { state.paymentEntities = true });
              const { result: yookassaPaymentResult } = await createYookassaPayment({ reservationUid: reservationUid, payerEmail: email });
              //сохраним данные платежа, чтобы при закрытии виджета не создавать новый платежж
              setPaymentData({
                confirmationToken: yookassaPaymentResult?.confirmationToken ?? '',
                paymentUid: yookassaPaymentResult?.paymentUid
              });
              //инициализация виджета
              initializeYooMoneyWidget({
                paymentData: yookassaPaymentResult ?? {},
                gameId: id ?? gameId,
                navigate,
                setLoadingState
              });
            }
          }
        } else {
          //кидаем ошибку, если недоступен сервис оплаты
          throw new Error('Сервис оплаты временно недоступен');
        }
      } catch(err) {
          ErrorUtils.handleErrorMessage(err);
      } finally {
        setLoadingState(state => {
          state.gameEntities = false;
        })
      }
    } else {
      //выполнится, если был закрыт виджет yookassa, а затем открыт снова
      try {
        setLoadingState(state => { state.paymentEntities = true });
        initializeYooMoneyWidget({
          paymentData: paymentData,
          gameId: id ?? newGameId,
          navigate,
          setLoadingState
        });
      } catch(err) {
        ErrorUtils.handleErrorMessage(err);
      }
    }
  }

  const handleRedirect = useCallback(() => {
    if(paymentData.confirmationToken && paymentData.paymentUid) {
      navigate(`/game/${id ?? newGameId}`);
    } else {
      onClose();
    }
  }, [onClose, id, newGameId, paymentData.confirmationToken, paymentData.paymentUid]);

  const parsedCourts = useMemo(() => {
    return formValues.selectedCourts.map(court => {
      try {
        return JSON.parse(court) as AvailableCourt;
      } catch(err) {
        return {};
      }
    })
  }, [formValues.selectedCourts]);

  useEffect(() => {
    if(parsedCourts) {
      paymentAmountCalculate({
        duration: Number(formValues.duration.replace(',', '.')) * 60,
        club: formValues.club?.reference,
        courts: parsedCourts.map(court => court.court ?? {}),
        date: toLocalDate(formValues.plannedDate.date),
        time: dayjs(formValues.plannedDate.time, 'H:mm').format('HH:mm:ss')
      })
        .then(({ result }) => {
          setTotalPrice(result?.result ?? 0);
        })
        .catch(err => ErrorUtils.handleErrorMessage(err));
    }
  }, [formValues, parsedCourts]);

  useEffect(() => {
    paymentForm.setFieldValue('checkboxEmail', !!paymentForm.values.email);
  }, [paymentForm.values.email]);

  return (  
    <>
      <FullscreenLoader loading={loadingState.paymentEntities || loadingState.gameEntities}/>
      <Header 
        className={styles['header']} 
        handleClick={handleRedirect}
        disabled={loadingState.gameEntities || loadingState.paymentEntities}
      >
        Оплата
      </Header>
      <form onSubmit={paymentForm.handleSubmit}> 
        <div className={styles['wrapper']}>
          <InnerPadding>
            <Headling appearence='medium-bold'>
              Корт
            </Headling>
            <div className={styles['court']}>
              <div className={styles['court-item']}>
                <div>
                  <CourtIcon />
                </div>
                <div className={styles['court-list']}>
                  {parsedCourts.map(court => (
                    <span key={court?.court?.uid}>{court?.court?.caption}</span>
                  ))}
                </div>
              </div>
              <div className={styles['court-item']}>
                <div>
                  <CalendarIcon/>
                </div>
                <span>
                  {formatter.formatDateToDayDateMonth(formValues.plannedDate.date)} | {formValues.plannedDate.time} 
                  {calculateEndTime(formValues.plannedDate.time ?? '', Number(formValues.duration.replace(',', '.')) * 60)}
                </span>
              </div>
              <div className={styles['court-item']}>
                <div>
                  <LocationIconOutline/>
                </div>
                <span>{formValues.club?.name}</span>
              </div>
            </div>
            <div className={styles['address']}>
              <span>{formValues.club?.mapAddress}</span>
              <span>Ежедневно с 07:00–23:00</span>
            </div>
          </InnerPadding>
          <Map
            defaultState={defaultYMapState}
            className={styles['map']}
            options={{
              restrictMapArea: true,
              copyrightLogoVisible: false,
              copyrightProvidersVisible: false,
              copyrightUaVisible: false,
              suppressMapOpenBlock: true,
            }}
          >
            <Placemark
              defaultGeometry={[55.881046, 37.450162]}
            />
          </Map>
          <InnerPadding>
            <Headling appearence='medium-bold'>
              Ваши данные
            </Headling>
          </InnerPadding>
          <div className={styles['form-wrapper']}>
            <div className={styles['hlwrapper']}>
              <HLine calssName={styles['hline']}/>
              <Input
                name='name'
                placeholder='Введите имя'
                autoComplete='off'
                onChange={paymentForm.handleChange} 
                value={paymentForm.values.name ?? ''} 
                enterKeyHint='done'
              />
              <HLine calssName={styles['hline']}/>
            </div>
            <div className={styles['hlwrapper']}>
              <HLine calssName={styles['hline']}/>
              <div className={styles['phone']}>
                <span style={{ alignSelf: 'flex-end' }}>+{current.countryCode}</span>&nbsp;
                <span style={{ alignSelf: 'flex-end' }}>{current.phone}</span>
              </div>
              <HLine calssName={styles['hline']}/>
            </div>
            <div className={styles['hlwrapper']}>
              <HLine calssName={styles['hline']} fieldsErrors={errorField === 'email'}/>
              <Input
                name='email'
                placeholder='Введите электронную почту'
                autoComplete='off'
                onChange={paymentForm.handleChange} 
                value={paymentForm.values.email ?? ''} 
                enterKeyHint='done'
                type='text'
                disabled={!!paymentData.paymentUid && !!paymentData.confirmationToken}
              />
              <HLine calssName={styles['hline']} fieldsErrors={errorField === 'email'}/>
            </div>
            <InnerPadding>
              <label className={`${styles['checkbox-label']} ${applyEmailCheckboxStyle(current.email ?? '', paymentForm.values.email ?? '', paymentForm.values.checkboxEmail)}`.trim()}>
                <CheckboxSquare
                  className={styles['checkbox']}
                  name='checkboxEmail'
                  disabled={(!paymentForm.values.email || (paymentForm.values.email === current.email)) || (!!paymentData.paymentUid && !!paymentData.confirmationToken)}
                  onChange={paymentForm.handleChange}
                  checked={paymentForm.values.checkboxEmail}
                />
                <span>
                  {current.email && (paymentForm.values.email === current.email) ? 
                    'Эта электронная почта уже сохранена' :
                    'Сохранить электронную почту в профиле для дальнейшего автозаполнения'
                  }
                </span>
              </label>
            </InnerPadding>
            <InnerPadding>
              <Headling appearence='big-bold' className={styles['total']}>
                <span>Итого:</span>
                <span>{totalPrice} ₽</span>
              </Headling>
            </InnerPadding>
          </div>
        </div>
          <OversideWrapper className={styles['button']}>
            <Button type='submit' disabled={loadingState.gameEntities || loadingState.paymentEntities}>
              Оплатить {totalPrice} ₽
            </Button>
          </OversideWrapper>
      </form>
    </>
  );
}
 
export default BookingPaymentPage;
